/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../state';
import { NavLink } from 'react-router-dom';
import UserService from '../services/userService';
import Role from '../../config/role';

import userImage from '../assets/images/icons/user.png';

const LeftMenuComponent = () => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.user);
	const { logout, menuToggle } = bindActionCreators(actions, dispatch);
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		if (user && user.hasOwnProperty('rol')) {
			setRoles(handleRoles());
		}
	}, [user]);

	return (
		<aside className="left-menu main-sidebar control-sidebar" style={{ paddingTop: 0 }}>
			<button id="toggle-menu" className="btn" data-toggle="push-menu" onClick={menuToggle}>
				<i className="fa fa-exchange" />
			</button>
			<section className="sidebar">
				<div className="user-panel">
					<div className="pull-left image">
						<img src={userImage} className="img-circle" alt="user" />
					</div>
					<div className="pull-left info">
						<p>
							<span id="lblUsuario">{user ? `${user.nombre}` : 'Cargando...'}</span>
						</p>
						<a href="#">
							<i className="fa fa-circle text-success" /> <span id="lblCliente">{user ? user.id : 'Cargando...'}</span>
						</a>
					</div>
				</div>
				<ul id="ulMenuList" className="sidebar-menu tree" data-widget="tree">
					{roles.map((rol, rolIndex) => {
						if (rol.hasOwnProperty('title')) {
							return (
								<li className="treeview" key={rolIndex}>
									<Dropdown title={rol.title} icon="fas fa-chart-pie">
										{rol.children.map((subRol, subRolIndex) => {
											return <li  key={subRolIndex}>{subRol}</li>;
										})}
									</Dropdown>
								</li>
							);
						} else {
							return <li  key={rolIndex}>{rol}</li>;
						}
					})}
					<li>
						<a>&nbsp;</a>
					</li>
					<li className="treeview">
						<Dropdown title="Mi Cuenta" icon="fa fa-user-circle">
							<li>
								<Link to="/Account/ChangePassword" title="Cambiar Contraseña" />
							</li>
						</Dropdown>
					</li>
					<li>
						<a href="#" onClick={() => logout()}>
							Cerrar Sesión
						</a>
					</li>
				</ul>
			</section>
		</aside>
	);
};

export const LeftMenu = memo(LeftMenuComponent);

const handleRoles = () => {
	var roles = [];

	const tieneCarpetasDigitalizacion = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.CarpetasDigitalizacion);
	const tieneDashboardDespachante = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.DashboardDespachante);

	const tieneReportesPremium = UserService.hasRole(Role.SupervisorDIGEX) 
			|| UserService.hasRole(Role.ReportesPremium) || UserService.hasRole(Role.ReportesPremiumFull);

	const tieneGenerarGuia = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GenerarGuia);
	const tieneConsultarGuia = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ConsultarGuia);
	const tieneDocumentacion = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.Documentacion);
	const tieneValidacionFactura = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ValidacionFactura);

	const tieneReportesB = UserService.hasRole(Role.SupervisorDIGEX) 
			|| UserService.hasRole(Role.ReporteBungeImportacion) || UserService.hasRole(Role.ReporteBungeExportacion)
			|| UserService.hasRole(Role.ReporteBungeMovimiento) || UserService.hasRole(Role.ReporteBungeSaldo)
			|| UserService.hasRole(Role.ReporteBungeBoleta) || UserService.hasRole(Role.ReporteBungeDetallado);

	const tieneReporteLDC = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteLDC);
	const tieneReporteL = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteLImportacion);
	const tieneInstructivoIM = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.InstructivoIM);
	const tieneInstructivoDE = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.InstructivoDE);
	const tieneGuiaRecepcionar = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GuiaRecepcionar);
	const tieneABMNovedades = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMNovedades);
	const tieneABMClientes = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMClientes);
	const tieneABMUsuarios = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ADMUsuarios);
	const tieneControlValidaciones = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ControlValidaciones);
	const tieneReporteDespachosLDC = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteDespachosLDC);
	
	const tienePyMoa = UserService.hasRole(Role.SupervisorDIGEX) 
			|| UserService.hasRole(Role.Barrido) || UserService.hasRole(Role.AvisoDIGI);

	const tieneCuentaCorriente = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.CuentaCorriente);
	const tieneFacturacion = UserService.hasRole(Role.ZySFacturacion);
	const tieneABMContrato = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMContrato);
	const tieneSeguimiento = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.Seguimiento);
	const tieneAltaPreUsuario = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.AltaPreUsuario);
	const tieneDJVEBunge = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.DJVEBunge);
	const tieneClaveFiscal = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ClaveFiscal);
	const tieneConsultarDJVEBunge = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ConsultarDjve);
	const tieneABMFestivos = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMFestivos);

	const tieneAltaPreUsuarioExterno = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.AltaPreUsuarioExterno);
	const tieneGeneradorDeUsuarios = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GeneradorDeUsuarios);

	const tieneContratosFaltantes = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.EjecutivaCuenta) || UserService.hasRole(Role.ZySFacturacion); 
	const tieneReportesDepositosAfectaciones = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReportesDepositosAfectaciones)
	if(tieneCarpetasDigitalizacion){
		roles = [...roles, <Link to="/Operador/Principal" title="Inicio" />];
	}else if(tieneDashboardDespachante){
		roles = [...roles, <Link to="/Cliente/Principal" title="Inicio" />];
	}else if(tieneReportesPremium){
		roles = [...roles, <Link to="/Cliente/PrincipalIMEXPremium" title="Inicio" />];
	}else{
		roles = [...roles, <Link to="/Cliente/PrincipalIMEX" title="Inicio" />];
	}

	if (tieneGenerarGuia) {
		roles = [...roles, <Link to="/Cliente/GenerarGuia" title="Generar Guías" />];
	}

	if (tieneConsultarGuia) {
		roles = [...roles, <Link to="/Cliente/ConsultarGuia" title="Consultar Guías" />];
	}

	if (tieneDocumentacion) {
		roles = [...roles, <Link to="/Cliente/Documentacion" title="Buscar Documentación" />];
	}

	if (tieneValidacionFactura) {
		roles = [...roles, <Link to="/Cliente/ValidacionFactura" title="Validar Factura" />];
	}

	let menuReportes = { title: 'Reportes de Operaciones', children: [] };
	
	if (tieneReportesPremium) {
		menuReportes.children = [
			...menuReportes.children,
			<Link to="/Cliente/Reporte/OperacionesMes" title="Por Mes" />,
			<Link to="/Cliente/Reporte/OperacionesProducto" title="Por Producto" />,
			<Link to="/Cliente/Reporte/OperacionesDespachante" title="Por Despachante" />,
		];
	}

	if (tieneReportesB) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReportesB" title="Reportes B" />];
	}
	if (tieneReportesB) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReportesBCLiquidaciones" title="Reportes B con Liquidaciones" />];
	}
	
	if (tieneReportesDepositosAfectaciones) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReportesDepositosAfectaciones" title="Reportes Depositos y Afectaciones - Bunge" />];
	}
	if (tieneReporteLDC) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReporteLDC" title="Reporte LDC" />];
	}
	if (tieneReporteDespachosLDC) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReporteDespachosLDC" title="Reporte Despacho LDC" />];
	}

	if (tieneReporteL) {
		menuReportes.children = [...menuReportes.children, <Link to="/Cliente/Reporte/ReporteL" title="Reporte L" />];
	}

	if (menuReportes.children.length > 0) {
		roles = [...roles, menuReportes];
	}

	if(tieneInstructivoIM && tieneReportesPremium){
		roles = [...roles, <Link to="/Cliente/InstructivoIMPremium" title="Instructivo IMEX" />];
	}else if(tieneInstructivoIM){
		roles = [...roles, <Link to="/Cliente/InstructivoIM" title="Instructivo IMEX" />];
	}

	if (tieneInstructivoDE) {
		roles = [...roles, <Link to="/Cliente/InstructivoDE" title="Instructivo Despachante" />];
	}

	if (tieneGuiaRecepcionar) {
		roles = [...roles, <Link to="/Operador/Validar2" title="Recepción Guías" />];
	}

	if (tieneABMNovedades) {
		roles = [...roles, <Link to="/Admin/ABMNovedades" title="Administrar Novedades" />];
	}

	if (tieneABMClientes) {
		roles = [...roles, <Link to="/Admin/ABMClientes" title="Administrar Clientes del Sistema" />];
	}

	if (tieneABMFestivos) {
		roles = [...roles, <Link to="/Admin/ABMFestivos" title="Administrar Festivos" />];
	}

	if (tieneABMUsuarios) {
		roles = [...roles, <Link to="/Admin/ABMUsuarios" title="Administrar Usuarios del Sistema" />];
	}

	if(tieneControlValidaciones){
		roles = [...roles, <Link to="/Operador/ControlValidaciones" title="Control Validaciones" />];
	}

	if (tienePyMoa) {
		roles = [...roles, <Link to="/Operador/Pymoa" title="Pymoa" />]
	}

	//if (UserService.hasRole(Role.ClaveFiscal)) {
	//	roles = [...roles, <Link to="/Cliente/CargarClaveFiscal" title="Clave Fiscal" />];
	//}

	if(tieneCuentaCorriente){
		roles = [...roles, <Link to="/Cliente/CuentaCorriente" title="Cuenta Corriente" />]
	}

	if(tieneFacturacion){
		roles = [...roles, <Link to="/Operador/Facturacion/Facturacion" title="Facturación" />]
	}

	if(tieneABMContrato){
		roles = [...roles, <Link to="/Operador/Facturacion/ABMContrato" title="Administrar Contratos del Sistema" />]
	}

	if (tieneSeguimiento) {
		roles = [...roles, <Link to="/Cliente/Seguimiento" title="Seguimiento" />];
	}

	if (tieneAltaPreUsuario) {
		roles = [...roles, <Link to="/Operador/Comercial/AltaUsuario" title="Alta Usuario Interno" />];
	}

	if (tieneDJVEBunge || tieneConsultarDJVEBunge){
		roles = [...roles, <Link to="/Cliente/Bunge/ConsultarDjve" title="Consultar Djve" />]
	}
	if(tieneDJVEBunge){
		roles = [...roles, <Link to="/Cliente/Bunge/AsociarDjve" title="Asociar Djve" /> ];
	}

	if (tieneAltaPreUsuarioExterno){
		roles = [...roles, <Link to="/Cliente/AltaPreUsuarioExterno" title="Alta Usuario" />]
	}

	if (tieneGeneradorDeUsuarios){
		roles = [...roles, <Link to="/Cliente/GeneradorDeUsuarios" title="Generador de Usuarios" />]
	}

	if (tieneContratosFaltantes){
		roles = [...roles, <Link to="/Operador/ContratosFaltantes" title="Contratos Faltantes" />]
	}

	if (tieneClaveFiscal){
		roles = [...roles, <Link to="/Cliente/CargarClaveFiscal" title="Clave Fiscal" />]
	}

	return roles;
};

const Link = ({ title, ...props }) => <NavLink {...props} exact>{title}</NavLink>;

const Dropdown = (props) => {
	const [toggle, setToggle] = useState(false);

	return (
		<Fragment>
			<button className="btn" onClick={() => setToggle((toggle) => !toggle)}>
				<i className={props.icon} />
				<span>{props.title}</span>
				<span className="pull-right-container">
					<i className={`fa fa-angle-left pull-right ${toggle ? 'dropdown-rotate' : ''}`} />
				</span>
			</button>
			<ul className={`treeview-menu ${toggle ? 'treeview-menu-expanded' : 'treeview-menu-collapsed'}`}>{props.children}</ul>
		</Fragment>
	);
};
