import { Modal } from '../components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { ocultarModal } from '../hooks/modal';

const Alerta = ({
	show,
	tipo = 'normal',
	tamaño = 'lg',
	titulo = 'Ha ocurrido un error en la ejecucion del sistema.',
	mensaje,
	handleCallback,
	className = '',
}) => {
	return (
		<Modal className={`popup ${className} ${tipo === 'error' ? 'error' : ''}`} show={show} onHide={() => ocultarModal('Alerta')} size={tamaño}>
			<Modal.Header closeButton>{typeof titulo === 'function' ? titulo() : titulo }</Modal.Header>
			<Modal.Body>{typeof mensaje === 'function' ? mensaje() : mensaje }</Modal.Body>
			<Modal.Footer>
				<div className="form-group">
					<button
						className="btn btn-success mt-2 float-right"
						onClick={() => {
							if (typeof handleCallback === 'function') {
								handleCallback();
							}

							ocultarModal('Alerta');
						}}
					>
						Aceptar
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

Alerta.propTypes = {
	show: PropTypes.bool,
	tipo: PropTypes.string,
	tamaño: PropTypes.string,
	titulo: PropTypes.string,
	handleCallback: PropTypes.func,
};

export default connectModal({ name: 'Alerta' })(Alerta);
