import { default as RealDatePicker } from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import { Portal } from 'react-overlays';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
import { useMemo } from 'react';
registerLocale('es', es);

export const DatePicker = ({ icon = false, porMes = false, ...props }) => {
	const dateFormat = useMemo(() => {
		if(porMes) 
			return "MM/yyyy";
		return "dd/MM/yyyy";
	}, [porMes])

	if(icon){
		return (
			<div className="input-group date">
				<div className="input-group-addon">
					<i className="fa fa-calendar" />
				</div>
				<RealDatePicker
					{...props}
					locale="es"
					disabledKeyboardNavigation
					shouldCloseOnSelect
					calendarStartDay={0}
					showMonthYearPicker={dateFormat === "MM/yyyy"}
					dateFormat={dateFormat}
					isClearable
					popperContainer={CalendarContainer}
					autoComplete="off"
				/>
			</div>
		)
	}

	return (
		<RealDatePicker
			{...props}
			locale="es"
			disabledKeyboardNavigation
			shouldCloseOnSelect
			calendarStartDay={0}
			showMonthYearPicker={dateFormat === "MM/yyyy"}
			dateFormat={dateFormat}
			isClearable
			popperContainer={CalendarContainer}
			autoComplete="off"
		/>
	);
};

const CalendarContainer = ({ children }) => {
	const el = document.getElementById('calendar-portal');
	return <Portal container={el}>{children}</Portal>;
};
