import { useState, useEffect } from 'react';
import { Loading, ModalAlerta } from '../../shared/components';
import { mostrarModal } from '../../shared/hooks/modal';
import useApi from '../../shared/hooks/api';
import logoweb from '../../shared/assets/images/logoweb.png';
import dgx from '../../shared/assets/images/dgx.png';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const RecuperarClave = (props) => {
	const history = useHistory();

	const { loadingCounter } = useSelector((state) => state.loader);

	const [usuario, asignarUsuario] = useState({
		token: props.match.params.token,
		nombreUsuario: '',
		contraseñaNueva: '',
		contraseñaNuevaConfirmacion: '',
	});

	const [validaciones, asignarValidaciones] = useState({
		longitudMinima: false,
		tieneMayusculas: false,
		tieneNumeros: false,
		tieneSimbolos: false,
	});

	const [, verificarTokenContraseña] = useApi.post('/verificarTokenContraseña', usuario, {
		lazy: true,
		onSuccess: ({ data }) => {
			if(data && data.hasOwnProperty('nombreUsuario')){
				asignarUsuario((state) => ({ ...state, nombreUsuario: data.nombreUsuario }));
			}
		},
		onError: () => {
			mostrarModal('Alerta', {
				titulo: 'Olvido Clave',
				mensaje: 'Su token es inválido, realice otra petición de recuperación de clave.',
				handleCallback: () => {
					history.push("/Account/Login");
				}
			});
		}
	});

	const [, cambiarContraseña] = useApi.post('/cambiarContraseñaPorToken', usuario, {
		lazy: true,
		onSuccess: () => {
			mostrarModal('Alerta', {
				titulo: 'Cambiar Clave',
				mensaje: 'Se ha actualizado su contraseña.',
				handleCallback: () => {
					history.push("/Account/Login");
				},
			});
		},
		onError: ({ error }) => {
			mostrarModal('Alerta', {
				titulo: 'Error al Cambiar Clave',
				mensaje: error,
			});
		},
	});

	useEffect(() => {
		let validaciones = { longitudMinima: false, tieneMayusculas: false, tieneNumeros: false, tieneSimbolos: false };

		//1: Verifica si contienen la minima cantidad de caracteres:
		if (usuario.contraseñaNueva !== '' && usuario.contraseñaNueva.length >= 9) {
			validaciones.longitudMinima = true;
		}

		//2: Verifica si contiene mayusculas
		if (usuario.contraseñaNueva.match(/[A-Z]/g) !== null && usuario.contraseñaNueva.match(/[A-Z]/g)[0].length > 0) {
			validaciones.tieneMayusculas = true;
		}

		//3: Verifica si tiene numeros
		if (usuario.contraseñaNueva.match(/\d+/g) !== null && usuario.contraseñaNueva.match(/\d/g).length >= 4) {
			validaciones.tieneNumeros = true;
		}

		//4: Verifica si tiene caracteres especiales
		if (usuario.contraseñaNueva.match(/[#¿?¡!@$%^*]/g) !== null && usuario.contraseñaNueva.match(/[#¿?¡!@$%^*]/g).length > 0) {
			validaciones.tieneSimbolos = true;
		}

		asignarValidaciones(validaciones);
	}, [usuario]);

	const manejarCambioClave = () => {
		if (!validaciones.longitudMinima || !validaciones.tieneMayusculas || !validaciones.tieneNumeros || !validaciones.tieneSimbolos) {
			mostrarModal('Alerta', { titulo: 'Cambiar Clave', mensaje: '¡Revise las recomendaciones de seguridad!' });
		} else if (usuario.contraseñaNueva !== usuario.contraseñaNuevaConfirmacion) {
			mostrarModal('Alerta', { titulo: 'Cambiar Clave', mensaje: 'Su nueva contraseña y confirmación deben coincidir.' });
		} else {
			cambiarContraseña();
		}
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(verificarTokenContraseña, []);
		
	return (
		<div id="AccountLogin">
			<div className="container-login">
				{/* BANNER */}
				<nav id="banner-login">
					<div className="my-3 px-4">
						<img src={logoweb} alt="" style={{ width: '185px', height: '65px', marginRight: '30px' }} />

						<div style={{ float: 'right', padding: '20px', marginRight: '30px' }}>
							<a href="/Inicio" className="text-decoration-none">
								INICIO
							</a>
						</div>
					</div>
				</nav>

				<div id="loginRecuperarClave" className="row justify-content-center">
					<div className="col-lg-6">
						<div className="box box-solid box-primary" style={{ backgroundColor: 'transparent' }}>
							<div className="box-header" style={{ marginBottom: 20, backgroundColor: 'transparent' }}>
								<h3 className="box-title">Olvido Clave:</h3>
							</div>

							<div className="box-body">
								<div
									style={{
										margin: 0,
										borderWidth: 2,
										borderStyle: 'solid',
										borderColor: '#0066ff',
										borderBottomColor: 'transparent',
										borderTopColor: 'transparent',
										borderRightColor: 'transparent',
									}}
									className="row mb-4"
								>
									<div style={{ fontSize: 19 }} className="col-12">
										<label htmlFor="lblUserName" id="lblUserNameLabel">
											Usuario:
										</label>
									</div>
									<div style={{ color: '#0066ff', fontSize: 19 }} className="col-12">
										<span id="lblUserName" style={{ display: 'inline-block', width: '100%' }}>
											{usuario.nombreUsuario !== '' ? usuario.nombreUsuario : '...'}
										</span>
									</div>
								</div>

								<div className="row">
									<div className="col-sm-12 col-md-6">
										<div className="row mb-2">
											<div className="col-12">
												<label htmlFor="nuevaClave" id="nuevaClave">
													Nueva contraseña:
												</label>
											</div>
											<div className="col-12">
												<input
													name="nuevaClave"
													autoComplete="off"
													type="password"
													id="nuevaClave"
													className="digex-control"
													defaultValue={usuario.contraseñaNueva}
													onChange={(e) => asignarUsuario({ ...usuario, contraseñaNueva: e.target.value })}
												/>
											</div>
										</div>
										<div className="row mb-4">
											<div className="col-12">
												<label htmlFor="confirmarClave" id="confirmarClave">
													Confirmar la nueva contraseña:
												</label>
											</div>
											<div className="col-12">
												<input
													name="confirmarClave"
													autoComplete="off"
													type="password"
													id="confirmarClave"
													className="digex-control"
													defaultValue={usuario.contraseñaNuevaConfirmacion}
													onChange={(e) => asignarUsuario({ ...usuario, contraseñaNuevaConfirmacion: e.target.value })}
												/>
											</div>
										</div>

										<div className="row mb-2">
											<div className="col-sm-12">
												<button className="btn btn-primary" onClick={manejarCambioClave}>Cambiar contraseña</button>
											</div>
										</div>
									</div>

									<div className="col-sm-12 col-md-6">
										<div className="box-body text-primary p-0">
											<h4 className="box-title ml-2">Recomendaciones de Seguridad</h4>
											<ul className="ml-2">
												<li className="mb-2">
													Las contraseñas nuevas deben tener:
													<ol className="text-secondary mt-2">
														<li className={`${validaciones.longitudMinima ? 'disable' : 'enable'}`}>Longitud mínima de 9 caracteres.</li>
														<li className={`${validaciones.tieneMayusculas ? 'disable' : 'enable'}`}>Al menos una mayúscula.</li>
														<li className={`${validaciones.tieneNumeros ? 'disable' : 'enable'}`}>Al menos 4 (cuatro) digitos numericos.</li>
														<li className={`${validaciones.tieneSimbolos ? 'disable' : 'enable'}`}>Al menos un caracter especial: # ? ! @ $ % ^ *.</li>
													</ol>
												</li>
												<li className="mb-2">No entregue ni divulgue sus credenciales de acceso al sistema.</li>
												<li className="mb-2">
													El sistema exigirá periódicamente el cambio de la contraseña, a fin de maximizar la seguridad del sistema.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ModalAlerta />

				<footer>
					<div id="footer-login">
						<hr />
						<div>
							<div className="text-right">
								<p>
									<a href="tel:+541143113741" className="text-decoration-none">
										[+5411] 4311.3741
									</a>
									<br />
									<a href="mailto:info@digex.com.ar" className="text-decoration-none">
										info@digex.com.ar
									</a>
									<br />
									
								</p>
							</div>
							<div className="text-center">
								<img src={dgx} className="mx-2" style={{ marginTop: '5px', width: '100px' }} alt="" />
							</div>
							<div className="text-left">
								<p>
									Paraguay 610. Piso 11
									<br />
									C.A.B.A. , Argentina
									<br />
									[C1057AAJ]
								</p>
							</div>
						</div>
					</div>
				</footer>
			</div>

			{loadingCounter > 0 && <Loading />}
		</div>
	);
};

export default RecuperarClave;
