export const authTypes = {
	loginRequest: 'loginRequest',
	loginSuccess: 'loginSuccess',
	loginFailure: 'loginFailure',
	loginClaveExpirada: 'loginClaveExpirada',
	logout: 'logout',
};

export const loaderTypes = {
	loadingClose: 'loadingClose',
	loadingShow: 'loadingShow',
};

export const userTypes = {
	userRequest: 'userRequest',
	userSuccess: 'userSuccess',
	userFailure: 'userFailure',
};

export const fetchTypes = {
	fetchRequest: 'fetchRequest',
	fetchSuccess: 'fetchSuccess',
	fetchFailure: 'fetchFailure'
}

export const progressTypes = {
	asignarPorcentaje: 'asignarPorcentaje',
}

export const menuTypes = {
	menuOpen: 'menuOpen',
	menuClose: 'menuClose',
	menuToggle: 'menuToggle'
}