import { authTypes } from '../types';
import TokenService from '../../services/tokenService';

const token = TokenService.getAccessToken();
const initialState = token ? { loggedIn: true, token: token, loading: false } : {};

export const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case authTypes.loginRequest:
			return { loggingIn: true, user: action.user, loading: true };
		case authTypes.loginSuccess:
			return { ...state, loggedIn: true, user: action.user, loading: false };
		case authTypes.loginFailure:
			return { loading: false, error: action.error };
		case authTypes.logout:
			return {};
		case authTypes.loginClaveExpirada:
			return { ...state, loggedIn: false, user: action.user, loading: false };
		default:
			return state;
	}
};
