import { Modal as DefaultModal } from 'react-bootstrap';

export const Modal = ({ show,scrollable, onHide, ...props }) => {
	return (
		<DefaultModal
			centered
			backdrop="static"
			keyboard={false}
			animation={true}
			size="lg"
			scrollable={scrollable}
			show={show}
			onHide={onHide}
			{...props}
		>
			{props.children}
		</DefaultModal>
	);
};

const Header = (props) => {
	return (
		<DefaultModal.Header {...props}>
			<DefaultModal.Title>{props.children}</DefaultModal.Title>
		</DefaultModal.Header>
	);
};

const Body = (props) => {
	return <DefaultModal.Body {...props} style={{ overflow: 'auto', maxHeight: 600 }}>{props.children}</DefaultModal.Body>;
};

const Footer = (props) => {
	return <DefaultModal.Footer {...props}>{props.children}</DefaultModal.Footer>;
};

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
