import { FormCheck } from 'react-bootstrap';
import { Util } from '../utils';
import { useState } from 'react';

export const Checkbox = ({ title, ...props }) => {
	const [id] = useState(Util.generarId());

	return (
		<FormCheck inline type="checkbox" id={id} style={{ cursor: 'pointer' }} {...props} />
	);
}
