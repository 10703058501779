import { Util } from '../utils';
import { Fragment, useState } from 'react';

export const FileInput = ({ label, className, style, multiple, manejadorSubidaArchivos }) => {
	const [id] = useState(Util.generarId());

	return (
		<Fragment>
			<label className="form-label" htmlFor={id}>{label}</label>
			<input 
				type="file" 
				id={id} 
				className={`form-control ${className || ''}`}
				multiple={multiple}
				style={style}
				onChange={(e) => manejadorSubidaArchivos(e)}
			/>
		</Fragment>
	)
}