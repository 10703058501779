import { loaderTypes } from '../types';

export const loadingStart = () => {
	return (dispatch) => {
		dispatch({ type: loaderTypes.loadingShow });
	};
};

export const loadingEnd = () => {
	return (dispatch) => {
		dispatch({ type: loaderTypes.loadingClose });
	};
};
