import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const TooltipComponent = ({mensajeTooltip,classTooltip,textDiv}) =>{

	const renderTooltip = props => (
		<Tooltip {...props}>{mensajeTooltip}</Tooltip>
	);
	
	return(
		<>
			<div>
				<OverlayTrigger placement="top" overlay={renderTooltip}>
					<div className="">
						<button className={classTooltip}>{textDiv}</button>
					</div>
				</OverlayTrigger>
			</div>
		</>
	)
}

