import api from './api';
import { store } from '../state';

const getCurrentUser = () => {
	return api.post('/obtenerUsuario')
		.then((response) => response.data);
};

const hasRole = (rolName, idIMEX, idDESP) => {
	var state = store.getState();
	var { user } = state.user;

	if(!user || !user.rol || user.rol.length === 0){
		return false;
	}

	if(idIMEX !== undefined || idDESP !== undefined){
		return user.rol.some((e) => e.idRol === rolName && (e.idCliente === idIMEX || e.idCliente === idDESP))
	}

	return user.rol.some((e) => e.idRol === rolName)
}

const UserService = {
	getCurrentUser,
	hasRole
};

export default UserService;
