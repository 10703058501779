import { useCallback, useEffect, useRef } from 'react';
import api from '../../services/api';
import useMergeState from '../mergeState';
import { isEqual } from 'lodash';
import useDeepCompareMemoize from '../deepCompareMemoize';

const useMutation = (method, url, propsVariables = {}, options = {}) => {
	const propsVariablesMemoized = useDeepCompareMemoize(propsVariables);
	const { lazy = false, cachePolicy = 'cache-first'} = options;
	const wasCalled = useRef(false);
	const isSleeping = lazy && !wasCalled.current;
	const isCacheAvailable = cache[url] && isEqual(cache[url].apiVariables, propsVariables);
	const canUseCache = isCacheAvailable && cachePolicy !== 'no-cache' && !wasCalled.current;

	const [state, mergeState] = useMergeState({
		data: [],
		cantidadFilas: 0,
		error: null,
		isWorking: false,
		isLoading: !lazy && !canUseCache,
		fueEnviado: false,
		variables: {},
	});

	const makeRequest = useCallback(
		(newVariables) =>
			new Promise((resolve, reject) => {
				const variables = { ...state.variables, ...(newVariables || {}) };
				const apiVariables = { ...propsVariablesMemoized, ...variables };

				mergeState({ isWorking: true });

				const skipLoading = canUseCache && cachePolicy === 'cache-first';
				if (!skipLoading) {
					mergeState({ isLoading: true, variables });
				} else if (newVariables) {
					mergeState({ variables });
				}

				const headers = options && options.hasOwnProperty('headers') ? options.headers : undefined;

				api[method](url, apiVariables, headers).then(
					(response) => {
						const res = response.data;

						resolve(res);

						if(options && options.hasOwnProperty('onSuccess') && typeof options.onSuccess === 'function'){
							options.onSuccess({ 
								filtro: apiVariables,
								data: res.hasOwnProperty('data') ? res.data : [],
								error: null
							});
						}

						mergeState({ 
							data: res.hasOwnProperty('data') ? res.data : [], 
							cantidadFilas: res.hasOwnProperty('cantidadRegistrosTotal') ? res.cantidadRegistrosTotal : 0, 
							error: null, 
							isWorking: false, 
							isLoading: false,
							fueEnviado: true
						});
					
					},
					(error) => {
						resolve(error);

						var messageError = "Error interno del sistema.";

						try{
							messageError = error.response.data.error;
						}catch(err){
							messageError = "Error interno del sistema.";
						}

						if(options && options.hasOwnProperty('onError') && typeof options.onError === 'function'){
							options.onError({ 
								filtro: apiVariables,
								data: null,
								error: messageError
							});
						}

						mergeState({ 
							data: [], 
							cantidadFilas: 0, 
							error: messageError,
							isWorking: false, 
							isLoading: false,
							fueEnviado: true,
						});

					}
				);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[method, url, propsVariablesMemoized, mergeState]
	);

	useEffect(() => {
		if (isSleeping) return;
		
		makeRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [makeRequest]);

	return [
		{
			...state,
			[isWorkingAlias[method]]: state.isWorking,
		},
		makeRequest,
	];
};

const isWorkingAlias = {
	post: 'isCreating',
	put: 'isUpdating',
	patch: 'isUpdating',
	delete: 'isDeleting',
};
const cache = {};
export default useMutation;
