function getLegajoTipoDocumentacionById(id) {
	const tDocLegajos = [
		'DGA - CARPETA COMPLETA',
		'DGA - CARPETA COMPLETA',
		'DGA - RECTIFICATIVA B/TOTAL',
		'DGA - RECTIFICATIVA B/PARCIAL',
		'DGA - DOCUMENTACION POST LIBRAMIENTO',
		'DGA - LEGAJO ELECTRONICO DEL VIAJE DEL MEDIO DE TRANSPORTE ACUATICO',
		'DOC COMERCIAL - FACTURA',
	]

	try {
		return tDocLegajos[parseInt(id)]
	} catch {
		return '-'
	}
}

function obtenerDescripcionEstadoUsuario(idEstado) {

	var descripcion = "";

	switch (idEstado) {
		case 1:
			descripcion = "Entregado";
			break;
		case 2:
			descripcion = "Visualizado";
			break;
		case 3:
			descripcion = "Usuario Generado";
			break;
		case 4:
			descripcion = "Renovado y Entregado";
			break;
		case 5:
			descripcion = "Eliminado";
			break;
		default:
			descripcion = "-";
	}

	return descripcion;
}

function formatDate(date) {
	if (date && date !== '') {
		return new Date(date).toLocaleDateString('es-AR', {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		});
	}

	return '';
}

const manejadorDeTecla = (e) => {
	const invalidChars = ['-', '+', 'e', '.', 'E',',']
	if(invalidChars.indexOf(e.key) !== -1){
		e.preventDefault()
	}
}

function validarEmail(email) {
	const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return mailRegex.test(email);
}

function validarCuit(cuit) {
	//\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]
	const cuitRegex = /\d{11}/;
	return cuitRegex.test(cuit);
}

// /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/
function validarTelefono(telefono) {
	//\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]
	const cuitRegex = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
	return cuitRegex.test(telefono);
}

function generarId(){
	return (Math.random() + 1).toString(36).substring(2);
}

function obtenerNumeroFormateado(numero){
	if(numero === undefined || numero === null) 
		return "";
	
	return numero.toFixed(2).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function descargar(archivo, nombre = "fileName"){
	if(!archivo) return

	var a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	var blob = new Blob([archivo], {type: "application/octet-stream"});
	var url = window.URL.createObjectURL(blob);
	a.download = nombre
	a.href = url;
	a.click();
	window.URL.revokeObjectURL(url);
}

function abrirArchivo(archivo, nombre = "fileName"){

	const fileName = nombre && `${ nombre }.pdf`;
    const newBlob = new Blob([archivo], {type: "application/pdf"});
	const newWindow = window.open('', fileName, "width=800,height=1200");
	if ((newWindow) !== null) {
		const dataUrl = window.URL.createObjectURL(newBlob);
		const title = newWindow.document.createElement('title');
		const iframe = newWindow.document.createElement('iframe');

		title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);
            iframe.setAttribute('src', dataUrl);
            iframe.setAttribute('width', "100%");
            iframe.setAttribute('height', "100%");
            newWindow.document.body.appendChild(iframe);
			window.URL.revokeObjectURL(dataUrl);
	}
}

function bytesToSize(bytes) {
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

	if (bytes === 0){
		return '0B'
	}

	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

	if (i === 0){
		return `${bytes} ${sizes[i]})`
	}

	return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`
}

export const Util = {
	getLegajoTipoDocumentacionById,
	formatDate,
	validarEmail,
	validarCuit,
	validarTelefono,
	generarId,
	obtenerDescripcionEstadoUsuario,
	obtenerNumeroFormateado,
	descargar,
	abrirArchivo,
	manejadorDeTecla,
	bytesToSize
}
