import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Util } from "../utils";

export const Loading = () => {
	const { loaded, total } = useSelector((state) => state.progress);

	const porcentaje = total !== 0 ? Math.floor(loaded * 100 / total) : 0;
	const tamañoCargado = Util.bytesToSize(loaded);
	const tamañoTotal = Util.bytesToSize(total);

	if(porcentaje === 0){
		return (
			<div id="Loading">
				<div className="overlay overlay-show">
					<div className="loader loader-load" />
				</div>
			</div>
		);
	}

	return (
		<div id="Loading">
			<div className="overlay overlay-show">
				<div className="progress-loader">
					<div className="row mb-2">
						<span className="col-sm-12 text-white">Subiendo archivos...</span>
						<span className="col-sm-12 col-md-6 text-white">
							{porcentaje}%
						</span>
						<span className="col-sm-12 col-md-6 text-white text-right">
							{tamañoCargado} / {tamañoTotal}
						</span>
					</div>

					<ProgressBar now={porcentaje} />
				</div>
			</div>
		</div>
	);
};
