import { Accordion, Card as DefaultCard } from 'react-bootstrap';

export const Card = ({ disableHeader, closeButton, title, ...props }) => {
	const header = disableHeader ? null : (
		<DefaultCard.Header className="box-title">
			{title ? title : 'Búsqueda'} {closeButton && <Accordion.Toggle eventKey="0" as={Button}></Accordion.Toggle>}
		</DefaultCard.Header>
	);

	return (
		<Accordion defaultActiveKey="0" className="mt-2 mb-4">
			<DefaultCard>
				{header}
				<Accordion.Collapse eventKey="0">
					<DefaultCard.Body className={disableHeader ? 'p-0' : ''} {...props}>
						{props.children}
					</DefaultCard.Body>
				</Accordion.Collapse>
			</DefaultCard>
		</Accordion>
	);
};

export const Button = (props) => {
	return (
		<div className="box-tools pull-right">
			<button {...props} type="button" className="btn btn-sm btn-card-collapse btn-box-tool" data-widget="collapse">
				<i className="fa fa-minus" />
			</button>
		</div>
	);
};
