import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../state';
import logoweb from '../assets/images/logoweb.png';

export const BannerAdmin = () => {
	const dispatch = useDispatch();
	const { logout } = bindActionCreators(actions, dispatch);
	const { user } = useSelector((state) => state.user);

	return (
		<div id="BannerAdmin">
			<Navbar expand="lg">
				<Container>
					<Navbar.Brand>
						<img src={logoweb} alt="Digex Logo" />
					</Navbar.Brand>
					<Navbar.Toggle />
					<Navbar.Collapse className="justify-content-end">
						<Nav>
							<Nav.Link href="/Cliente/Principal" className="align-middle">
								Inicio
							</Nav.Link>
							<Nav.Link href="#" disabled>Mi Empresa</Nav.Link>
							<Nav.Link href="#" onClick={() => logout()}>
								Cerrar Sesión
							</Nav.Link>
							<NavDropdown title="Herramientas">
								<NavDropdown.Item href="/Operador/Principal" className="text-decoration-none text-primary">
									Desktop Operador
								</NavDropdown.Item>
								<NavDropdown.Item href="/Operador/Recepcion" className="text-decoration-none text-primary">
									Recepción
								</NavDropdown.Item>
								<NavDropdown.Item href="/Operador/Digitalizar" className="text-decoration-none text-primary">
									Digitalizar
								</NavDropdown.Item>
								<NavDropdown.Item href="/Operador/Confirmar" className="text-decoration-none text-primary">
									Confirmar
								</NavDropdown.Item>
								<NavDropdown.Item href="/Operador/ProgramarRetiro" className="text-decoration-none text-primary">
									Programar Retiro
								</NavDropdown.Item>
							</NavDropdown>
							<Nav.Link href="/Account/ChangePassword" style={{ borderLeft: '1px solid #fff' }} className="ml-3 pl-3">
								<h4 style={{ fontSize: 18 }}>
									<i className="fa fa-user mr-2" />
									<span id="lblUsuario">{user ? `${user.nombre}` : 'Cargando...'}</span>
								</h4>
								<h5 style={{ fontSize: 14 }}>
									<span id="lblCliente">{user ? user.id : 'Cargando...'}</span>
								</h5>
							</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</div>
	);
};
