import { useState } from 'react';
import { Modal } from '../../../shared/components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { mostrarModal, ocultarModal } from '../../../shared/hooks/modal';
import useApi from '../../../shared/hooks/api';

const OlvidoClave = ({ show }) => {
	const [filtro, asignarFiltro] = useState({
		nombreUsuario: '',
	});

	const [, olvidoClave] = useApi.post('/olvidoClave', filtro, { 
		lazy: true,
		onSuccess: () => {
			mostrarModal('Alerta', { 
				titulo: 'Olvidé mi Clave', 
				mensaje: 'Enviamos el link de restauracion de contraseña a tu mail.',
				handleCallback: () => {
					ocultarModal('OlvidoClave');
				}
			})
		},
		onError: ({ error }) => {
			mostrarModal('Alerta', { 
				titulo: 'Olvidé mi Clave', 
				mensaje: error,
			})
		}
	});

	return (
		<Modal className="popup" show={show} onHide={() => ocultarModal('OlvidoClave')} size="md">
			<Modal.Header closeButton>Olvidé mi clave</Modal.Header>
			<Modal.Body>
				<label htmlFor="nombreUsuario" className="w-100">
					Ingrese su CUIT registrado:
				</label>
				<input 
					type="text"
					autoComplete="off"
					className="form-control"
					id="nombreUsuario"
					name="nombreUsuario"
					defaultValue={filtro.nombreUsuario}
					onChange={(e) => asignarFiltro({ ...filtro, nombreUsuario: e.target.value.trim() })}
				/>
			</Modal.Body>
			<Modal.Footer>
				<button type="reset" className="btn btn-danger" onClick={() => ocultarModal('OlvidoClave')}>
					Cancelar
				</button>
				<button type="submit" className="btn btn-success" onClick={() => olvidoClave(filtro)}>
					Aceptar
				</button>
			</Modal.Footer>
		</Modal>
	);
};

OlvidoClave.propTypes = {
	show: PropTypes.bool,
};

export default connectModal({ name: 'OlvidoClave' })(OlvidoClave);
