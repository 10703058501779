export const pedidoLegajoTypes = {
	pedidoInicializar: 'pedidoInicializar',
	pedidoToggle: 'pedidoToggle',
	pedidoToggleAll: 'pedidoToggleAll'
};

// ACTIONS

export const pedidoInicializar = () => {
	return (dispatch) => {
		dispatch({ type: pedidoLegajoTypes.pedidoInicializar });
	};
}

export const pedidoToggle = (carpeta) => {
	return (dispatch) => {
		dispatch({ type: pedidoLegajoTypes.pedidoToggle, carpeta });
	};
}

export const pedidoToggleAll = (carpetas) => {
	return (dispatch) => {
		dispatch({ type: pedidoLegajoTypes.pedidoToggleAll, carpetas });
	};
}

const actions = {
	pedidoInicializar,
	pedidoToggle,
	pedidoToggleAll
}

export default actions;

// REDUCERS

export const pedidoLegajoReducer = (state = [], action) => {
	switch (action.type) {
		case pedidoLegajoTypes.pedidoInicializar:
			return [];
		case pedidoLegajoTypes.pedidoToggle:
			var existeEnArray = state.filter((x) => x.idCarpeta === action.carpeta.idCarpeta).length !== 0;
			
			if(existeEnArray){
				return state.map((pedido) => {
					if(pedido.idCarpeta === action.carpeta.idCarpeta){
						return { ...pedido, estaAgregada: !pedido.estaAgregada }
					}
					return pedido;
				});
			}

			return state.concat({ ...action.carpeta, estaAgregada: true });
		case pedidoLegajoTypes.pedidoToggleAll:
			var carpetasSeleccionadas = state.filter((x) => x.estaAgregada === true);

			if(carpetasSeleccionadas.length === action.carpetas.length){
				return [];
			}

			return action.carpetas.map((carpeta) => {
				return { ...carpeta, estaAgregada: true }
			});
		default:
			return state;
	}
};
