import { menuTypes } from '../types';

export const menuOpen = () => {
	return (dispatch) => {
		dispatch({ type: menuTypes.menuOpen });
	};
};

export const menuClose = () => {
	return (dispatch) => {
		dispatch({ type: menuTypes.menuClose });
	};
};

export const menuToggle = () => {
	return (dispatch) => {
		dispatch({ type: menuTypes.menuToggle });
	};
};
