import { Fragment } from 'react';
import { Util } from '../utils';

export const Input = ({ label = null, ...props }) => {
	const id = Util.generarId();

	return (
		<Fragment>
			{label && <label htmlFor={id}>{label}</label>}
			<input type="text" autoComplete="off" className="form-control" {...props} id={id} name={id} />
		</Fragment>
	);
};
