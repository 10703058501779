import { memo } from 'react';
import { default as DefaultSelect } from 'react-select';

export const SelectComponent = (props) => {
	return (
		<DefaultSelect
			noOptionsMessage={() => 'Sin resultados'}
			placeholder=""
			menuPortalTarget={document.body}
			menuPlacement="auto"
			hideSelectedOptions={false}
			isSearchable
			{...props}
		></DefaultSelect>
	);
};

export const Select = memo(SelectComponent)