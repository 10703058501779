import { menuTypes } from '../types';

const initialState = { showMenu: true };

export const menuReducer = function (state = initialState, action) {
	switch (action.type) {
		case menuTypes.menuOpen:
			return { showMenu: true };
		case menuTypes.menuClose:
			return { showMenu: false };
		case menuTypes.menuToggle:
			return { showMenu: !state.showMenu }
		default:
			return state;
	}
};