import { useSelector } from 'react-redux';
import { BannerAdmin, Loading, ModalAlerta } from '../components';

export const ContainerAdmin = (props) => {
	const { loadingCounter } = useSelector((state) => state.loader);
	return (
		<div id="ContainerAdmin">
			<BannerAdmin />
			
			<div {...props} className="content-wrapper">
				{props.children}
			</div>

			{loadingCounter > 0 && <Loading />}

			<ModalAlerta />
		</div>
	);
};

const Header = (props) => (
	<section className="content-header" {...props}>
		{props.children}
	</section>
);
const Body = (props) => (
	<section className="content" {...props}>
		{props.children}
	</section>
);

ContainerAdmin.Header = Header;
ContainerAdmin.Body = Body;
