/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';
import InfoWindow from 'react-google-maps/lib/components/InfoWindow';
import { compose } from 'recompose';

const defaultPosition = { lat: -34.6036844, lng: -58.381559100000004 };

export const Map = compose(
	withScriptjs,
	withGoogleMap
)(({ mapa, receptorias }) => {
	const [marcadores, asignarMarcadores] = useState({
		zoom: 6,
		marcadoresMapa: null,
		posicion: defaultPosition,
	});

	const [marcadorSeleccionado, asignarMarcadorSeleccionado] = useState(null);

	useEffect(() => {
		if (mapa.provincia && mapa.receptoria) {
			asignarMarcadores((state) => {
				const marcadores = receptorias.filter((x) => x.idReceptoria === mapa.receptoria);
				return {
					...state,
					zoom: 14,
					marcadoresMapa: marcadores,
					posicion: { lat: marcadores[0].latitudReceptoria, lng: marcadores[0].longitudReceptoria },
				};
			});
		} else if (mapa.provincia) {
			asignarMarcadores((state) => {
				const marcadores = receptorias.filter((x) => x.idJurisdiccion === mapa.provincia);
				return {
					...state,
					zoom: 5,
					marcadoresMapa: marcadores,
					posicion: { lat: marcadores[0].latitudJurisdiccion, lng: marcadores[0].longitudJurisdiccion },
				};
			});
		} else {
			asignarMarcadores((state) => ({ ...state, zoom: 5, marcadoresMapa: null, posicion: defaultPosition }));
		}
	}, [mapa, receptorias]);

	return (
		<GoogleMap zoom={marcadores.zoom} center={marcadores.posicion} scrollwheel={false} draggable={true}>
			{marcadores.marcadoresMapa !== null ? (
				marcadores.marcadoresMapa.map((marcador) => {
					return (
						<Marker
							key={marcador.idReceptoria}
							animation={mapa.provincia && mapa.receptoria ? 1 : null}
							defaultPosition={{ lat: marcador.latitudReceptoria, lng: marcador.longitudReceptoria }}
							onClick={() => asignarMarcadorSeleccionado(marcador)}
						/>
					);
				})
			) : (
				<Marker defaultPosition={defaultPosition} />
			)}

			{marcadorSeleccionado !== null && marcadorSeleccionado.direccion !== '' ? (
				<InfoWindow
					position={{ lat: marcadorSeleccionado.latitudReceptoria, lng: marcadorSeleccionado.longitudReceptoria }}
					onCloseClick={() => asignarMarcadorSeleccionado(null)}
				>
					{marcadorSeleccionado.direccion ? (
						<p>
							{marcadorSeleccionado.zona} - {marcadorSeleccionado.direccion}
						</p>
					) : (
						<p>{marcadorSeleccionado.zona}</p>
					)}
				</InfoWindow>
			) : null}
		</GoogleMap>
	);
});
