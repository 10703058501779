import React, { useState, useEffect, useMemo } from "react";
import { Fragment } from "react";

export const DOTS = '...';

const range = (start, end) => {
	let length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export const Pagination = ({ initialFilter = { contadorBusqueda: 0 }, onPageChange, isLoading = false, totalRows = 0, initialLimit = 25, className = "" }) => {
	const [filter, setFilter] = useState({
		...initialFilter,
		paginaActual: 1,
		registrosPorPagina: initialLimit
	});

	useEffect(() => {
		setFilter({ ...initialFilter, paginaActual: 1, registrosPorPagina: initialLimit });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialFilter.contadorBusqueda])

	useEffect(() => {
		onPageChange(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter.paginaActual, filter.registrosPorPagina, filter.contadorBusqueda]);

	const sizePagination = 3;
	const paginationValues = [-1, 25, 50, 100];
	const maxPage = filter.registrosPorPagina < 0 || totalRows === 0 ? 1 : Math.ceil(totalRows / filter.registrosPorPagina);
	const pages = range(1, maxPage);

	const paginationRange = usePagination({
		totalPageCount: maxPage,
		currentPage: filter.paginaActual,
		totalRows: totalRows,
		siblingCount: sizePagination,
		registrosPorPagina: filter.registrosPorPagina
	});

	const onPageIndexChange = (page) => {
		if (page !== undefined && Number(page) > 0 && Number(page) < totalRows) {
			setFilter((current) => ({ ...current, paginaActual: Number(page) }))
		}
	}

	const onPageSizeChange = (page) => {
		if (page !== undefined && paginationValues.indexOf(Number(page)) > -1) {
			setFilter((current) => ({ ...current, registrosPorPagina: Number(page), paginaActual: 1 }))
		}
	}

	useEffect(() => {
		if(!isLoading && totalRows > 0){
			window.scrollTo(0, document.body.scrollHeight);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading && totalRows])

	if (isLoading || !paginationRange) {
		return <Fragment />;
	}

	return (
		<div className={`row mt-2 ${className}`}>
			<div className="col-sm-12 col-md-2 pagination-pages-container">
				<div className="form-group mx-4">
					<label htmlFor="paginationIndexSelect">Página</label>

					<select
						id="paginationIndexSelect"
						className="form-control form-control-sm"
						defaultValue={filter.paginaActual}
						onChange={(e) => onPageIndexChange(e.target.value)}
					>
						{pages.map(pageNumber => {
							return <option key={pageNumber} value={pageNumber}>{pageNumber}</option>
						})}
					</select>
				</div>
			</div>

			<div className="col-sm-12 col-md-8">
				<ul className="pagination">
					<li className="page-item">
						<button 
							className="btn btn-sm page-link"
							disabled={filter.paginaActual === 1}
							onClick={() => setFilter((current) => ({ ...current, paginaActual: current.paginaActual - 1 }))}
						>
							<i className="bi bi-chevron-left"></i>
						</button>
					</li>

					{paginationRange.map((pageNumber, i) => {
						if (pageNumber === DOTS) {
							return (
								<li key={i} className="disabled">
									<span className="not-page-link">&#8230;</span>
								</li>
							)
						}

						return (
							<li key={i}
								className={`page-item ${pageNumber === filter.paginaActual ? 'active' : ''}`}
								onClick={() => onPageIndexChange(pageNumber)}
							>
								<span className="page-link">{pageNumber}</span>
							</li>
						);
					})}

					<li className="page-item">
						<button 
							className="btn btn-sm page-link"
							disabled={filter.paginaActual === paginationRange[paginationRange.length - 1]}
							onClick={() => setFilter((current) => ({ ...current, paginaActual: current.paginaActual + 1 }))}
						>
							<i className="bi bi-chevron-right"></i>
						</button>
					</li>
				</ul>
			</div>

			<div className="col-sm-12 col-md-2 pagination-rows-container">
				<div className="form-group mx-4">
					<label htmlFor="paginationSizeSelector">Filas por página</label>
					<select
						id="paginationSizeSelector"
						className="form-control form-control-sm"
						defaultValue={filter.registrosPorPagina}
						onChange={(e) => onPageSizeChange(e.target.value)}
					>
						{paginationValues.map(sizeNumber => {
							if (sizeNumber === -1) {
								return <option key={sizeNumber} value={sizeNumber}>TODOS</option>
							}

							return <option key={sizeNumber} value={sizeNumber}>{sizeNumber}</option>
						})}
					</select>
				</div>
			</div>
		</div>
	);
};

export const usePagination = ({
	totalPageCount,
	totalRows,
	registrosPorPagina,
	siblingCount = 1,
	currentPage
}) => {
	const paginationRange = useMemo(() => {
		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		/*
		  If the number of pages is less than the page numbers we want to show in our
		  paginationComponent, we return the range [1..totalPageCount]
		*/
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(
			currentPage + siblingCount,
			totalPageCount
		);

		/*
		  We do not want to show dots if there is only one position left 
		  after/before the left/right page count as that would lead to a change if our Pagination
		  component size which we do not want
		*/
		const shouldShowLeftDots = leftSiblingIndex >= 2;
		const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			let leftItemCount = (3 + 2 * siblingCount) - 1;
			let leftRange = range(1, leftItemCount);
			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			let rightItemCount = 3 + 2 * siblingCount;
			let rightRange = range(
				totalPageCount - rightItemCount + 2,
				totalPageCount
			);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			let middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalRows, registrosPorPagina, siblingCount, currentPage]);
	return paginationRange;
};