import api from '../../../shared/services/api';
import { fetchTypes } from '../../../shared/state/types';

// ACTIONS

export const obtenerDetalleCliente = (idCliente) => {
	return (dispatch) => {
		dispatch({ type: fetchTypes.fetchRequest, idCliente: Number(idCliente) });

		api.post('/obtenerDetalleCliente', { idCliente: Number(idCliente) })
			.then((res) => {
				dispatch({ type: fetchTypes.fetchSuccess, data: res.data.data });
			}, (error) => {
				dispatch({ type: fetchTypes.fetchFailure, error });
			});
	};
};

const actions = {
	obtenerDetalleCliente
}

export default actions;

// REDUCERS

const initialState = { idCliente: 0, data: {}, error: null, isLoading: false }

export const detalleClienteReducer = (state = initialState, action) => {
	switch (action.type) {
		case fetchTypes.fetchRequest:
			return { idCliente: action.idCliente, data: {}, error: null, isLoading: true };
		case fetchTypes.fetchSuccess:
			return { ...state, error: null, data: action.data, isLoading: false };
		case fetchTypes.fetchFailure:
			return { ...state, error: action.error, isLoading: false };
		default:
			return state;
	}
};
