import { Modal } from '../../../shared/components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { ocultarModal } from '../../../shared/hooks/modal';

const LoginErroneo = ({ show }) => {
	return (
		<Modal className="popup" show={show} onHide={() => ocultarModal('LoginErroneo')} size="lg">
			<Modal.Header closeButton>El usuario o la contraseña son incorrectas.</Modal.Header>
			<Modal.Body>
				<p>Verifíquelos antes de volver a intentarlo ya que su usuario o contraseña pueden bloquearse.</p>
			</Modal.Body>
		</Modal>
	);
};

LoginErroneo.propTypes = {
	show: PropTypes.bool
};

export default connectModal({ name: 'LoginErroneo' })(LoginErroneo);
