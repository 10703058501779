export const validarFacturaTypes = {
	facturaInicializar: 'facturaInicializar',
	facturaToggle: 'facturaToggle',
	facturaToggleAll: 'facturaToggleAll'
};

// ACTIONS

export const facturaInicializar = () => {
	return (dispatch) => {
		dispatch({ type: validarFacturaTypes.facturaInicializar });
	};
}

export const facturaToggle = (carpeta) => {
	return (dispatch) => {
		dispatch({ type: validarFacturaTypes.facturaToggle, carpeta });
	};
}

export const facturaToggleAll = (carpetas) => {
	return (dispatch) => {
		dispatch({ type: validarFacturaTypes.facturaToggleAll, carpetas });
	};
}

const actions = {
	facturaInicializar,
	facturaToggle,
	facturaToggleAll
}

export default actions;

// REDUCERS

export const validarFacturaReducer = (state = [], action) => {
	switch (action.type) {
		case validarFacturaTypes.facturaInicializar:
			return [];
		case validarFacturaTypes.facturaToggle:
			var existeEnArray = state.filter((x) => x.idLegajo === action.carpeta.idLegajo).length !== 0;
			if(existeEnArray){
				return state.map((factura) => {
					if(factura.idLegajo === action.carpeta.idLegajo){
						return { ...factura, estaAgregada: !factura.estaAgregada }
					}
					return factura;
				});
			}

			return state.concat({ idLegajo: action.carpeta.idLegajo, estaAgregada: true });
		case validarFacturaTypes.facturaToggleAll:
			var legajosSeleccionados = state.filter((x) => x.estaAgregada === true);

			if(legajosSeleccionados.length === action.carpetas.length){
				return [];
			}

			return action.carpetas.map((x) => {
				return { idLegajo: x.idLegajo, estaAgregada: true }
			});
		default:
			return state;
	}
};
