import { loaderTypes } from '../types';

const initialState = { loadingCounter: 0 };

export const loaderReducer = function (state = initialState, action) {
	switch (action.type) {
		case loaderTypes.loadingShow:
			return { ...state, loadingCounter: state.loadingCounter + 1 };
		case loaderTypes.loadingClose:
			return { ...state, loadingCounter: state.loadingCounter - 1 };
		default:
			return state;
	}
};