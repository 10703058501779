import { Modal } from '../../shared/components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { ocultarModal } from '../../shared/hooks/modal';

const ProductoDIGEXIA = ({ show }) => {
	return (
		<Modal className="popup popup-productos" show={show} onHide={() => ocultarModal('ProductoDIGEXIA')} size="lg" style={{ padding: 0 }}>
			<Modal.Header closeButton>DIGEX-IA</Modal.Header>
			<Modal.Body>
				<p>
					Es la solución que permite optimizar los procesos de recepción, captura y análisis de los datos contenidos en facturas de venta, facturas de
					compra, órdenes de pago, recibos de venta, recibos de compra, órdenes de compra, remitos y otros.
				</p>

				<p>
					DIGEX-IA es una herramienta lógica para el análisis de datos sensibles basada en inteligencia artificial desarrollada por programadores
					expertos en data analytics y big data.
				</p>

				<p>Beneficios de utilizar DIGEX-IA:</p>
				<p className="mb-0">Reduce costos.</p>
				<p className="mb-0">Elimina el error humano.</p>
				<p className="mb-0">Incorpora velocidad de acceso y seguridad a sus datos sensibles.</p>
				<p className="mb-0">
					Permite el acceso a toda la documentación digitalizada y procesada a través de nuestro web service las 24 hs. los 365 días del año.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-primary" onClick={() => ocultarModal('ProductoDIGEXIA')}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
};

ProductoDIGEXIA.propTypes = {
	show: PropTypes.bool,
};

export default connectModal({ name: 'ProductoDIGEXIA' })(ProductoDIGEXIA);
