import { Modal } from '../../shared/components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { ocultarModal } from '../../shared/hooks/modal';

const ProductoDIGEX824Premium = ({ show }) => {
	return (
		<Modal className="popup popup-productos" show={show} onHide={() => ocultarModal('ProductoDIGEX824Premium')} size="lg" style={{ padding: 0 }}>
			<Modal.Header closeButton>DIGEX824 Premium</Modal.Header>
			<Modal.Body>
				<p>
					P.S.A.D. (Prestador de servicio de archivo y digitalización) es la denominación impuesta por la AFIP para las empresas homologadas para
					brindar el servicio de almacenamiento y digitalización de documentación aduanera. DIGEX S.R.L. es el quinto P.S.A.D. aprobado por la AFIP en
					casi diez años de existencia de este régimen, a los efectos de que las empresas que operan en comercio exterior puedan cumplir con lo
					establecido en la resolución general de la AFIP Nro. 2573/09, de cumplimiento obligatorio para todos los exportadores e importadores, la
					cual busca la simplificación de los procesos aduaneros e implica una migración hacia una aduana sin papeles, concepto que se ajusta a los
					objetivos y recomendaciones de OMA (Organización Mundial de Aduanas) para la promoción del comercio.
				</p>

				<p>La resolución que nos homologa como P.S.A.D. también nos sindica como depositario fiel, y en tal sentido asumimos la responsabilidad de:</p>

				<p>
					Digitalizar, archivar y resguardar la documentación por el plazo de 10 años, siguiendo el procedimiento de archivo estipulado en dicha
					resolución.
				</p>

				<p>Aportar toda la documentación que requiera la AFIP remitiendo electrónicamente las imágenes digitalizadas.</p>

				<p>
					A través de nuestro sistema DIGEX824 Premium, proveemos acceso a nuestro generador de reportes personalizado que le permite relevar su
					información de un modo dinámico y eficiente. Garantizamos la disponibilidad de acceso remoto a su documentación las 24 hs del día durante
					los 365 días del año.
				</p>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-primary" onClick={() => ocultarModal('ProductoDIGEX824Premium')}>
					Cerrar
				</button>
			</Modal.Footer>
		</Modal>
	);
};

ProductoDIGEX824Premium.propTypes = {
	show: PropTypes.bool,
};

export default connectModal({ name: 'ProductoDIGEX824Premium' })(ProductoDIGEX824Premium);
