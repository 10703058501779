import useMutation from './mutation';
import useDownload from './download';

/* eslint-disable react-hooks/rules-of-hooks */

const api = {
	get: (...args) => useMutation('get', ...args),
	post: (...args) => useMutation('post', ...args),
	put: (...args) => useMutation('put', ...args),
	patch: (...args) => useMutation('patch', ...args),
	delete: (...args) => useMutation('delete', ...args),
	download: (...args) => useDownload('download', ...args),
	upload: (...args) => useDownload('upload', ...args)
};

export default api;
