import { authTypes } from '../types';
import AuthService from '../../services/authService';
import { history } from '../../utils';
import { mostrarModal } from '../../hooks/modal';
import TokenService from '../../services/tokenService';

export const login = (username, password) => {
	return (dispatch) => {
		dispatch({ type: authTypes.loginRequest, username });
		AuthService.login(username, password).then(
			(user) => {
				if(user.tieneClaveExpirada === 0){
					if (user.tokenAcceso) {
						TokenService.setUser(user);
					}

					dispatch({ type: authTypes.loginSuccess, user });
					history.push('/');
				}else{
					dispatch({ type: authTypes.loginClaveExpirada, user });
				}
			},
			(error) => {
				dispatch({ type: authTypes.loginFailure, error });
				mostrarModal('LoginErroneo');
			}
		);
	};
};

export const logout = () => {
	return (dispatch) => {
		AuthService.logout();
		dispatch({ type: authTypes.logout });
	};
};
