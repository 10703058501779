import { Fragment, useEffect, useState, useMemo } from 'react';
import { ModalAlerta, Table } from '../../shared/components';
import { mostrarModal } from '../../shared/hooks/modal';
import { Util } from '../../shared/utils';
import useApi from '../../shared/hooks/api';
import { useHistory } from 'react-router';

const FormularioAU = (props) => {
	const history = useHistory();

	const [filtro, asignarFiltro] = useState({
		token: props.match.params.token,
		usuarios: [],
	});

	const [usuario, asignarUsuario] = useState({
		id: 0,
		nombreUsuario: '',
		cuitUsuario: '',
		emailUsuario: '',
		contraseña: null,
	});

	const [usuarioError, asignarUsuarioError] = useState({});
	// verificarUsuarioPorToken

	const [, verificarUsuarioPorToken] = useApi.post('/verificarUsuarioPorToken', filtro, {
		lazy: true,
		onError: () => {
			mostrarModal('Alerta', {
				titulo: 'Generar Usuarios',
				mensaje: 'Su token es inválido, realice otra petición de generación de usuarios.',
				handleCallback: () => {
					history.push('/Default');
				},
			});
		},
	});

	const [, crearUsuarioPorToken] = useApi.post('/crearUsuarioPorToken', filtro, {
		lazy: true,
		onSuccess: () => {
			mostrarModal('Alerta', {
				titulo: 'Generar Usuarios',
				mensaje: () => {
					return (
						<Fragment>
							<p>
								Los usuarios fueron generados correctamente. Por favor revise las casillas de los emails ingresados recientemente para poder
								comenzar a utilizar nuestro sistema.
							</p>
							<p>Como este proceso ha finalizado sera redireccionado automaticamente a la pantalla de inicio.</p>
						</Fragment>
					);
				},
				handleCallback: () => {
					window.location.href = '/Default';
				},
			});
		},
		onError: ({ error }) => {
			mostrarModal('Alerta', {
				titulo: 'Error al generar usuarios',
				mensaje: error,
			});
		},
	});

	const manejarAgregarUsuario = () => {
		let usuarioError = {};

		if (usuario.cuitUsuario === '' || usuario.cuitUsuario.length !== 11) {
			usuarioError.cuitUsuario = 'La C.U.I.T. ingresada no es valida';
		}

		if (usuario.nombreUsuario === '') {
			usuarioError.nombreUsuario = 'El Nombre de usuario es incorrecto';
		}

		if (usuario.emailUsuario === '' || !Util.validarEmail(usuario.emailUsuario)) {
			usuarioError.emailUsuario = 'El email ingresado no es valido';
		}
		if (usuario.contraseña === '' || usuario.contraseña === null) {
			usuarioError.contraseña = 'la clave ingresada no es valida';
		}

		const usuariosRepetidos = filtro.usuarios.filter((x) => x.emailUsuario === usuario.emailUsuario || x.cuitUsuario === usuario.cuitUsuario);

		if (usuariosRepetidos.length > 0) {
			usuarioError.repetidos = 'Este usuario ya esta agregado.';
		}

		asignarUsuarioError(usuarioError);

		if (Object.keys(usuarioError).length === 0) {
			asignarFiltro((state) => ({ ...state, usuarios: [...state.usuarios, usuario] }));
			asignarUsuario({ id: 0, nombreUsuario: '', cuitUsuario: '', emailUsuario: '', contraseña: null });
		}
	};

	useEffect(() => {
		asignarUsuario((state) => ({ ...state, id: filtro.usuarios.length + 1 }));
	}, [filtro.usuarios]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(verificarUsuarioPorToken, []);

	const rows = useMemo(() => filtro.usuarios || [], [filtro.usuarios]);
	const columns = useMemo(
		() => [
			{ Header: 'Razon Social', accessor: (r) => `${r.nombreUsuario}` },
			{ Header: 'C.U.I.T.', accessor: 'cuitUsuario' },
			{ Header: 'Email', accessor: 'emailUsuario' },
			{
				Header: ' ',
				accessor: (r) => (
					<button className="btn btn-danger" onClick={() => manejarEliminarUsuario(r.id)}>
						Eliminar
					</button>
				),
			},
		],
		[]
	);

	const manejarEliminarUsuario = (usuarioId) => {
		asignarFiltro((state) => ({ ...state, usuarios: state.usuarios.filter((x) => x.id !== usuarioId) }));
	};

	return (
		<div id="FormularioAU">
			<div className="row">
				<div className="col-sm-12 col-md-5">
					<div className="col-sm-12">
						<div className="row">
							<div className="col-sm-12 text-white box-header">
								<h3>¡Bienvenido a 824!</h3>
							</div>
						</div>
					</div>

					<div className="col-sm-12">
						{filtro.usuarios.length === 10 && <div className="alert alert-danger mx-3">Solo puede agregar hasta 10 usuarios.</div>}
					</div>

					<div className="col-sm-12">{usuarioError['repetidos'] && <div className="alert alert-danger mx-3">{usuarioError['repetidos']}</div>}</div>

					<div className="col-sm-12" style={{ borderRight: '1px solid rgb(35 123 255)' }}>
						<div className="row">
							<div className="col-sm-12">
								<div className="form-group">
									<label htmlFor="razonSocial" className="text-white">
										(*) Razon Social:
									</label>
									<input
										type="text"
										autoComplete="off"
										name="razonSocial"
										id="razonSocial"
										className="form-control form-control-sm"
										value={usuario.nombreUsuario}
										onChange={(e) => asignarUsuario({ ...usuario, nombreUsuario: e.target.value.trim() })}
									/>
									<span className="text-danger mt-1">{usuarioError['nombreUsuario']}</span>
								</div>
							</div>

							<div className="col-sm-12">
								<div className="form-group">
									<label htmlFor="cuit" className="text-white">
										(*) C.U.I.T.:
									</label>
									<input
										type="number"
										name="cuit"
										autoComplete="off"
										id="cuit"
										className="form-control form-control-sm"
										value={usuario.cuitUsuario}
										onChange={(e) => asignarUsuario({ ...usuario, cuitUsuario: e.target.value.trim() })}
									/>
									<span className="text-danger mt-1">{usuarioError['cuitUsuario']}</span>
								</div>
							</div>

							<div className="col-sm-12">
								<div className="form-group">
									<label htmlFor="email" className="text-white">
										(*) E-Mail:
									</label>
									<input
										type="mail"
										name="email"
										autoComplete="off"
										id="email"
										className="form-control form-control-sm"
										value={usuario.emailUsuario}
										onChange={(e) => asignarUsuario({ ...usuario, emailUsuario: e.target.value.trim() })}
									/>
									<span className="text-danger mt-1">{usuarioError['emailUsuario']}</span>
								</div>
							</div>

							<div className="col-sm-12">
								<div className="form-group">
									<label htmlFor="Password">Contraseña:</label>
									<input
										type="password"
										name="Password"
										id="Password"
										className="digex-control"
										autoComplete="off"
										required
										value={filtro.contraseña}
										onChange={(e) => asignarUsuario({ ...usuario, contraseña: e.target.value.trim() })}
									/>
									<span className="text-danger mt-1">{usuarioError['contraseña']}</span>
								</div>
							</div>

							<div className="col-sm-12">
								<p className="text-white">
									(*) <span className="text-danger">Completar con los datos del usuario a generar.</span>
								</p>
							</div>
							<div className="col-sm-12 col-md-6">
								<button className="btn btn-success w-100" onClick={manejarAgregarUsuario} disabled={filtro.usuarios.length === 10}>
									Agregar
								</button>
							</div>
							<div className="col-sm-12 col-md-6">
								<button
									className={`btn btn-success w-100 ${filtro.usuarios.length === 0 ? 'd-none' : ''}`}
									onClick={crearUsuarioPorToken}
									disabled={filtro.usuarios.length === 0}
								>
									Generar Usuarios
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="col-sm-12 col-md-7 pt-3">{filtro.usuarios.length > 0 && <Table className="text-white" columns={columns} rows={rows} />}</div>
			</div>

			<ModalAlerta />
		</div>
	);
};

export default FormularioAU;
