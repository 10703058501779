import Role from '../../config/role';
import logo824Premium from '../assets/images/logoweb824p.png';
import logo824 from '../assets/images/logoweb824.png';
import UserService from '../services/userService';

export const Banner = () => {
	const tieneReportesPremium = UserService.hasRole(Role.SupervisorDIGEX) 
								|| UserService.hasRole(Role.ReportesPremium) || UserService.hasRole(Role.ReportesPremiumFull);

	return (
		<div id="Banner" className="wrapper" style={{ height: 'auto', minHeight: '100%' }}>
			<header className="main-header">

				{tieneReportesPremium && <img src={logo824Premium} id="imgLogo" alt="" />}
				{!tieneReportesPremium && <img src={logo824} id="imgLogo" alt="" />}

				<div id="box-agente">
					<div className="d-none d-md-block">
						Operador de cuenta disponible
						{/*<a href="mailto:pamerica@digex.com.ar?Subject=Consulta" target="_top"  style="">Operador de cuenta no disponible</a>*/}
					</div>
					<div>
						<span id="span-agente-status-online">
							<i className="fa fa-user"></i>
						</span>
					</div>
				</div>
			</header>
		</div>
	);
};
