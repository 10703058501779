import { Accordion,Card } from 'react-bootstrap';

export const Collapse = ({ title, active, ...props})=>{
	return (
		<Accordion className='w-100 mb-4' style={{ cursor: 'pointer' }} defaultActiveKey={active ? "0" : null }>
			<Card>
				<Accordion.Toggle as={Card.Header} eventKey="0">
					<div className="text-truncate">
						{title}
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse eventKey="0">
					<Card.Body>
						{props.children}
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	)
}