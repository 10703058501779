export const procesarCarpetasTypes = {
	procesarCarpetas: 'carpetaModificar',
	procesoInicializar: 'Inicializar',
	reiniciar:'Reiniciar'
};

// ACTIONS


export const modificarCarpetasProcesar = (carpeta,legajo,pdfsMarcados) => {
	return (dispatch) => {
		dispatch({ type: procesarCarpetasTypes.procesarCarpetas, carpeta, legajo,pdfsMarcados});
	};
}


export const inicializar = (pdfsMarcados) => {
	return (dispatch) => {
		dispatch({ type: procesarCarpetasTypes.procesoInicializar,pdfsMarcados});
	};
}

export const reiniciar = (pdfsMarcados) => {
	return (dispatch) => {
		dispatch({ type: procesarCarpetasTypes.reiniciar});
	};
}


const actionsProcesar = {
	modificarCarpetasProcesar,
	inicializar,
	reiniciar
}

export default actionsProcesar;

// REDUCERS

export const procesarLegajosCarpeta = (state = [], action) => {
	switch (action.type) {
		case procesarCarpetasTypes.procesoInicializar:
			// eslint-disable-next-line no-redeclare
			return action.pdfsMarcados?.map((legajo) => {
				const idCarpetas = (legajo.idCarpeta.map((carpeta)=>Number(carpeta.split(".")[0])))
				return {...legajo, codLegajo:legajo.codLegajo, idCarpeta:idCarpetas,sinCarpetaDoble:true} 
			})
			
		case procesarCarpetasTypes.procesarCarpetas:
			// eslint-disable-next-line no-redeclare
			return state.map((data) => {
				if(data.codLegajo === action.legajo){
					return {...data, idCarpeta:[action.carpeta],sinCarpetaDoble:false} 
				}
				return data
			})
		case procesarCarpetasTypes.reiniciar:
			// eslint-disable-next-line no-redeclare
			return []

		default:
			return action;
	}
};
