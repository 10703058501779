import api from './api';
import TokenService from './tokenService';

const login = (username, password) => {

	return api.post('/login', {
			nombreUsuario: username,
			contrasena: password,
		})
		.then((response) => {
			return response.data.data;
		});
};

const logout = () => {
	TokenService.removeUser();
};


const AuthService = {
	login,
	logout,
};

export default AuthService;
