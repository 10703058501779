import { Dropdown as RealDropdown } from 'react-bootstrap';
import { Portal } from 'react-overlays';

export const Dropdown = ({ children, ...props }) => {
	return (
		<div className="dropdown">
			<RealDropdown {...props}>{children}</RealDropdown>
		</div>
	);
};

export const Menu = ({ children, ...props }) => {
	const el = document.getElementById('dropdown-portal');
	return (
		<Portal container={el}>
			<RealDropdown.Menu {...props}>{children}</RealDropdown.Menu>
		</Portal>
	);
};

Dropdown.Toggle = RealDropdown.Toggle;
Dropdown.Menu = Menu;
Dropdown.Item = RealDropdown.Item;
