import { useCallback, useEffect, useRef } from 'react';
import api from '../../services/api';
import useMergeState from '../mergeState';
import { isEqual } from 'lodash';
import useDeepCompareMemoize from '../deepCompareMemoize';

const useDownload = (method, url, propsVariables = {}, options = {}) => {
	const propsVariablesMemoized = useDeepCompareMemoize(propsVariables);
	const { lazy = false, cachePolicy = 'cache-first'} = options;
	const wasCalled = useRef(false);
	const isSleeping = lazy && !wasCalled.current;
	const isCacheAvailable = cache[url] && isEqual(cache[url].apiVariables, propsVariables);
	const canUseCache = isCacheAvailable && cachePolicy !== 'no-cache' && !wasCalled.current;

	const [state, mergeState] = useMergeState({
		data: null,
		error: null,
		isWorking: false,
		isLoading: !lazy && !canUseCache,
		variables: {},
		fueEnviado: false,
	});

	const makeRequest = useCallback(
		(newVariables) =>
			new Promise((resolve, reject) => {
				const variables = { ...state.variables, ...(newVariables || {}) };
				const apiVariables = { ...propsVariablesMemoized, ...variables };

				mergeState({ isWorking: true });

				const skipLoading = canUseCache && cachePolicy === 'cache-first';
				if (!skipLoading) {
					mergeState({ isLoading: true, variables });
				} else if (newVariables) {
					mergeState({ variables });
				}

				const requestOptions = options && options.hasOwnProperty('options') ? options.options : undefined;

				api[method](url, apiVariables, undefined, requestOptions).then(
					(response) => {
						const res = response.data;

						resolve(res);

						var nombreArchivo = null;
						try{
							nombreArchivo = response.headers['content-disposition'].split("filename=")[1];
						}catch(err){}
						
						if(options && options.hasOwnProperty('onSuccess') && typeof options.onSuccess === 'function'){
							options.onSuccess({ 
								filtro: apiVariables,
								data: res,
								error: null,
								nombreArchivo: nombreArchivo
							});
						}

						mergeState({ 
							data: res,
							error: null, 
							isWorking: false, 
							isLoading: false,
							fueEnviado: true,
						});
					},
					async (error) => {
						resolve(error);

						var messageError = "Error interno del sistema.";

						try{
							const isJsonBlob = error.response.data instanceof Blob && error.response.data.type === "application/json";
							
							if(isJsonBlob){
								const promiseError = () => {
									return new Promise((resolve, reject) => {
										var blob = new Blob([error.response.data], {type: "application/json"});
										const reader = new FileReader();
										reader.onload = function() {
											let jsonResponse = JSON.parse(reader.result);
											resolve(jsonResponse.error);
										}
										reader.onerror = () => {
											reject("Error interno del sistema.");
										};
										reader.readAsText(blob);
									});
								}
								messageError = await Promise.resolve(promiseError());
							}else{
								messageError = error.response.data.error;
							}
						}catch(err){
							messageError = "Error interno del sistema.";
						}

						if(options && options.hasOwnProperty('onError') && typeof options.onError === 'function'){
							options.onError({ 
								filtro: apiVariables,
								data: null,
								error: messageError,
								nombreArchivo: null,
							});
						}

						mergeState({ 
							data: null,
							error: messageError, 
							isWorking: false, 
							isLoading: false,
							fueEnviado: true,
						});
					}
				);


			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[method, url, propsVariablesMemoized, mergeState]
	);

	useEffect(() => {
		if (isSleeping) return;
		
		makeRequest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [makeRequest]);

	return [
		{
			...state,
			[isWorkingAlias[method]]: state.isWorking,
		},
		makeRequest,
	];
};

const isWorkingAlias = {
	post: 'isCreating',
	put: 'isUpdating',
	patch: 'isUpdating',
	delete: 'isDeleting',
	download: 'isDownloading',
	upload: 'isUploading',
};
const cache = {};

export default useDownload;
