import { memo, useState, Fragment } from 'react';
// eslint-disable-next-line no-unused-vars
import { useTable, useSortBy, usePagination } from 'react-table';

const sortDate = (rowA, rowB, columnId) => {
	if(rowA.values[columnId] === ""){
		return -1;
	}

	if(rowB.values[columnId] === ""){
		return -1
	}

	const aDate = rowA.values[columnId].split("/").reverse().join("-");
	const bDate = rowB.values[columnId].split("/").reverse().join("-");

	return new Date(aDate) - new Date(bDate);
};

const TableComponent = ({ 
	columns, 
	rows: data = [], 
	className, 
	isLoading = false, 
	handleEditableCell,
	defaultMessage,
	getRowProps = () => ({}), 
	...props
}) => {
	const [editableFilaIndice, asignarEditableFilaIndice] = useState([]);

	const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
		{
			columns,
			data: data || [],
			manualPagination: true,
			disableMultiSort: true,
			handleEditableCell,
			editableFilaIndice,
			asignarEditableFilaIndice,
			autoResetPage: false,
			disableSortRemove: true,
		},
		useSortBy,
		usePagination
	);

	return (
		<Fragment>
			<div className="table-fixed">
				<table className={`table table-bordered ${className}`} {...props} {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									if(column.hasOwnProperty('hidden') && column.hidden){
										return null;
									}

									if(column.hasOwnProperty('canSortBy') && column.canSortBy){
										if(typeof column.sortType === 'string'){
											if (column.sortType === 'date'){
												column.sortType = sortDate;
											}
										}

										return (
											<th 
												{...column.getHeaderProps(column.getSortByToggleProps())} 
												title="Ordenar"
												style={{ cursor: 'pointer' }}
											>
												{column.render('Header')}

												<span className="ml-1">
													{column.isSorted ? column.isSortedDesc ? 
														<i className="bi bi-arrow-down"></i> : 
														<i className="bi bi-arrow-up"></i> : 
														<i className="bi bi-arrow-down-up"></i>
													}
												</span>
											</th>
										)
									}

									return (
										<th {...column.getHeaderProps()} title="Ordenar">
											{column.render('Header')}
										</th>
									)
								})}
							</tr>
						))}
					</thead>

					{!isLoading && rows.length > 0 && (
						<tbody {...getTableBodyProps()}>
							{rows.map((row) => {
								prepareRow(row);
								return (
									<tr key={row.id} {...row.getRowProps(getRowProps(row))}>
										{row.cells.map((cell) => {
											if(cell.column.hasOwnProperty('hidden') && cell.column.hidden){
												return null;
											}

											return (
												<td {...cell.getCellProps([{
														className: cell.column.className,
														style: cell.column.style,
													}])}
												>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					)}

					{!isLoading && rows.length === 0 && (
						<tbody className="sinRegistros">
							<tr>
								<td colSpan="10" align="center">
									<h5>{defaultMessage ? defaultMessage : "No se han encontrado resultados para la búsqueda."}</h5>
								</td>
							</tr>
						</tbody>
					)}
				</table>
			</div>
		</Fragment>
	);
};

export const Table = memo(TableComponent);