import { progressTypes } from '../types';

const initialState = { loaded: 0, total: 0 };

export const progressReducer = (state = initialState, action) => {
	switch (action.type) {
		case progressTypes.asignarPorcentaje:
			return { ...state, loaded: action.loaded, total: action.total };
		default:
			return state;
	}
};
