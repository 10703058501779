import { Fragment, useState } from 'react';

export const TextoElipsis = ({ texto = "", ...props }) => {
	const [mostrarTexto, asignarMostrarTexto] = useState(false);

	if (texto.length <= 513) {
		return <p {...props}>{texto}</p>;
	}

	return (
		<Fragment>
			<div style={{ overflow: "hidden" }}>
				<p {...props}>
					{mostrarTexto ? texto : texto.substring(0, 485)}
					<span>...</span>
				</p>
			</div>
			<button className="btn btn-sm btn-link" style={{ color: "#3c8dbc", padding: 0 }} onClick={() => asignarMostrarTexto((mostrarTextoActual) => !mostrarTextoActual)}>
				{mostrarTexto ? "Mostrar menos" : "Mostrar más"}
			</button>
		</Fragment>
	);
};
