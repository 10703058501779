import { userTypes } from '../types';
import UserService from '../../services/userService';
import TokenService from '../../services/tokenService';

export const getCurrentUser = () => {
	return (dispatch) => {
		dispatch({ type: userTypes.userRequest });
		UserService.getCurrentUser().then(
			({ data: user }) => {
				dispatch({ type: userTypes.userSuccess, user });
			},
			(error) => {
				dispatch({ type: userTypes.userFailure, error });
				TokenService.removeUser();
			}
		);
	};
};