import { mostrarModal, ocultarModal } from "../hooks/modal";
import { Modal, Card } from '../components';
import { connectModal } from 'redux-modal';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from "react";
import useApi from '../hooks/api';

const NovedadesUsuario = ({ show }) => {
	const [{ data: dataNovedades, isLoading: isLoadingNovedades }, obtenerNovedades] = useApi.post('/obtenerNovedadesPorUsuario', { lazy: true });

	const [, insertarNotificadoUsuario] = useApi.post('/insertarNotificadoUsuario', {}, {
		lazy: true,
		onSuccess: () => {
			obtenerNovedades();
		},
		onError: ({ error }) => {
			mostrarModal('Alerta', { mensaje: error, tipo: 'error' });
		}
	});

	useEffect(() => {
		obtenerNovedades();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	if(!isLoadingNovedades && dataNovedades && dataNovedades.length > 0){
		return (
			<Modal className="popup" show={show} onHide={() => ocultarModal('NovedadesUsuario')} size="xl">
				<Modal.Header closeButton>Novedades</Modal.Header>
				<Modal.Body>
					{!isLoadingNovedades && dataNovedades && dataNovedades.map((novedad) => {
						return (
							<Card title={novedad.titulo}>
								<p dangerouslySetInnerHTML={{__html: novedad.contenido }}></p>
								<button 
									className="btn btn-success float-right mb-2" 
									onClick={() => insertarNotificadoUsuario({ idNovedad: novedad.idNovedad })}
								>
									Entendido
								</button>
							</Card>
						);
					})}
				</Modal.Body>
			</Modal>
		);
	}

	return <Fragment />
};

NovedadesUsuario.propTypes = {
	show: PropTypes.bool
};

export default connectModal({ name: 'NovedadesUsuario' })(NovedadesUsuario);

