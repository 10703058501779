import { useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from '../routes';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../shared/state';
import { history } from '../shared/utils';

import Default from '../pages/Default/Default';
import FormularioAU from '../pages/Formulario/FormularioAU';
import Login from '../pages/Account/Login/Login';
import RecuperarClave from '../pages/Account/RecuperarClave';
import AppRouter from './AppRouter';

export const LoginRouter = () => {
	const dispatch = useDispatch();
	const { loggedIn } = useSelector((state) => state.auth);
	const { getCurrentUser } = bindActionCreators(actions, dispatch);

	useEffect(() => {
		if (loggedIn) {
			getCurrentUser();
		}
	}, [getCurrentUser, loggedIn]);

	const regexApi = /^\/api\/.*/;
	if (regexApi.test(window.location.pathname)) {
		return <div />;
	} else {
		return (
			<Router history={history}>
				<Switch>
					{/* /Default */}
					<PublicRoute path="/Inicio" component={Default} type={loggedIn} />
					<PublicRoute path="/Formulario/FormularioAU/Token/:token" component={FormularioAU} type={false} />

					{/* /Account */}
					<PublicRoute path="/Account/Login" component={Login} type={loggedIn} />
					<PublicRoute path="/Account/RecuperarClave/Token/:token" component={RecuperarClave} type={false} />

					{/* / */}
					<PrivateRoute path="/" component={AppRouter} type={loggedIn} />
				</Switch>
			</Router>
		);
	}
};
