import { useState, useEffect } from 'react';

export const EditTextAreaCell = ({
	value: initialValue,
	row,
	name,
	handleEditableCell,
	placeholder,
	className
}) => {
	const [value, setValue] = useState(initialValue)

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	useEffect(() => {
		handleEditableCell(row,value,name)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return <textarea 
		maxLength="32"
		className={className}
		defaultValue={value} 
		placeholder={placeholder}
		onBlur={(e) => setValue(e.target.value.trim())}  
	/>
}