import { Fragment } from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import UserService from '../shared/services/userService';
import Role from '../config/role';

const NotFound = loadable(() => import('../pages/NotFound'));
const ChangePassword = loadable(() => import('../pages/Account/ChangePassword'));
const Home = loadable(() => import('../pages/Home'));
const ViewFamilias = loadable(() => import('../pages/ViewFamilias'));
const ViewDjves = loadable(() => import('../pages/ViewDjves'));


const Admin = {
	ABMClientes: loadable(() => import('../pages/Admin/ABMClientes/ABMClientes')),
	DetalleCliente: loadable(() => import('../pages/Admin/DetalleCliente/DetalleCliente')),
	ABMNovedades: loadable(() => import('../pages/Admin/ABMNovedades/ABMNovedades')),
	ABMFestivos: loadable(() => import('../pages/Admin/ABMFestivos/ABMFestivos')),
	ABMUsuarios: loadable(() => import('../pages/Admin/ABMUsuarios/ABMUsuarios')),
};

const Operador = {
	AltaUsuario: loadable(() => import('../pages/Operador/Comercial/AltaUsuario/AltaUsuario')),
	Facturacion: loadable(() => import('../pages/Operador/Facturacion/Facturacion/Facturacion')),
	ABMContrato: loadable(() => import('../pages/Operador/Facturacion/ABMContrato/ABMContrato')),
	Desktop: loadable(() => import('../pages/Operador/Desktop/Desktop')),
	ProcesarCarpeta: loadable(() => import('../pages/Operador/ProcesarCarpeta/ProcesarCarpeta')),
	Validar2: loadable(() => import('../pages/Operador/Validar2/Validar2')),
	Digitalizar: loadable(() => import('../pages/Operador/Digitalizar/Digitalizar')),
	ProgramarRetiro: loadable(() => import('../pages/Operador/ProgramarRetiro')),
	ControlValidaciones: loadable(() => import('../pages/Operador/ControlValidaciones/ControlValidaciones')),
	Confirmar: loadable(() => import('../pages/Operador/Confirmar/Confirmar')),
	Pymoa: loadable(() => import('../pages/Operador/Pymoa/Pymoa')),
	ContratosFaltantes: loadable(() => import('../pages/Operador/ContratosFaltantes/ContratosFaltantes'))
};

const Cliente = {
	DesktopIMEXPremium: loadable(() => import('../pages/Cliente/DesktopIMEXPremium')),
	DesktopIMEX: loadable(() => import('../pages/Cliente/DesktopIMEX')),
	Desktop: loadable(() => import('../pages/Cliente/Desktop')),
	GenerarGuia: loadable(() => import('../pages/Cliente/GenerarGuia/GenerarGuia')),
	ConsultarGuia: loadable(() => import('../pages/Cliente/ConsultarGuia/ConsultarGuia')),
	Documentacion: loadable(() => import('../pages/Cliente/Documentacion/Documentacion')),
	ValidacionFactura: loadable(() => import('../pages/Cliente/ValidacionFactura/ValidacionFactura')),
	InstructivoIM: loadable(() => import('../pages/Cliente/InstructivoIM')),
	InstructivoImpremium: loadable(() => import('../pages/Cliente/InstructivoImpremium')),
	InstructivoDE: loadable(() => import('../pages/Cliente/InstructivoDE')),
	CargarClaveFiscal: loadable(() => import('../pages/Cliente/CargarClaveFiscal')),
	Seguimiento: loadable(() => import('../pages/Cliente/Seguimiento/Seguimiento')),
	CuentaCorriente: loadable(() => import('../pages/Cliente/CuentaCorriente')),
	OperacionesMes: loadable(() => import('../pages/Cliente/Reporte/OperacionesMes')),
	OperacionesProducto: loadable(() => import('../pages/Cliente/Reporte/OperacionesProducto')),
	OperacionesDespachante: loadable(() => import('../pages/Cliente/Reporte/OperacionesDespachante')),
	ReportesB: loadable(() => import('../pages/Cliente/Reporte/ReportesB')),
	ReportesBCLiquidaciones: loadable(() => import('../pages/Cliente/Reporte/ReportesBCLiquidaciones')),
	ReportesDepositosAfectaciones: loadable(() => import('../pages/Cliente/Reporte/ReportesDepositosAfectaciones')),
	ReporteDespachosLDC: loadable(() => import('../pages/Cliente/Reporte/ReportesDespachosLDC')),
	ReporteLDC: loadable(() => import('../pages/Cliente/Reporte/ReporteLDC')),
	ReporteL: loadable(() => import('../pages/Cliente/Reporte/ReporteL')),
	PedidoLegajo: loadable(() => import('../pages/Cliente/PedidoLegajo/PedidoLegajo')),
	InfoValidados: loadable(() => import('../pages/Cliente/InfoValidados')),
	DjveMostrados: loadable(() => import('../pages/Cliente/Bunge/ConsultarDjve')),
	DjveDigitalizados: loadable(() => import('../pages/Cliente/Bunge/AsociarDjve')),
	AltaPreUsuarioExterno: loadable(() => import('../pages/Cliente/AltaPreUsuarioExterno/AltaPreUsuarioExterno')),
	GeneradorDeUsuarios: loadable(() => import('../pages/Cliente/GeneradorDeUsuarios/GeneradorDeUsuarios')),

};

const AppRouter = () => {
	const { user } = useSelector((state) => state.user);

	const tieneVerFamilias = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.VerFamilias);
	const tieneDJVEBunge = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.DJVEBunge);
	const tieneConsultarDJVEBunge = UserService.hasRole(Role.ConsultarDjve);
	if (user) {
		return (
			<Fragment>
				<Switch>
					{/* /Admin */}
					<Route
						path="/Admin"
						render={({ match: { url } }) => {
							const tieneABMClientes = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMClientes);
							const tieneABMNovedades = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMNovedades);
							const tieneABMUsuarios = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ADMUsuarios);
							const tieneABMFestivos = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMFestivos);

							return (
								<Switch>
									{tieneABMClientes && <Route path={`${url}/ABMClientes`} component={Admin.ABMClientes} exact />}
									{tieneABMClientes && <Route path={`${url}/DetalleCliente/:idCliente`} component={Admin.DetalleCliente} exact />}

									{tieneABMNovedades && <Route path={`${url}/ABMNovedades`} component={Admin.ABMNovedades} exact />}
									{tieneABMUsuarios && <Route path={`${url}/ABMUsuarios`} component={Admin.ABMUsuarios} exact />}
									{tieneABMFestivos && <Route path={`${url}/ABMFestivos`} component={Admin.ABMFestivos} exact />}
									
									<Route component={NotFound} />
								</Switch>
							);
						}}
					/>

					{/* /Operador */}
					<Route
						path="/Operador"
						render={({ match: { url } }) => {
							const tieneAltaPreUsuario = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.AltaPreUsuario);
							const tieneFacturacion = UserService.hasRole(Role.ZySFacturacion);
							const tieneABMContrato = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ABMContrato);
							const tieneCarpetasDigitalizacion = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.CarpetasDigitalizacion);
							const tieneConfirmarCarpeta = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ConfirmarCarpeta);
							const tieneGuiaRecepcionar = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GuiaRecepcionar);
							const tieneProgramarRetiro = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ProgramarRetiro);
							const tieneControlValidaciones = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ControlValidaciones);
							const tienePyMoa = UserService.hasRole(Role.SupervisorDIGEX) 
								|| UserService.hasRole(Role.ConsultarHistorialLegajo) || UserService.hasRole(Role.ModificarEstadoGuia)
								|| UserService.hasRole(Role.Barrido) || UserService.hasRole(Role.AvisoDIGI)
								|| UserService.hasRole(Role.ReprocesarDIGI);

							const tieneContratosFaltantes = UserService.hasRole(Role.ZySFacturacion) || UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.EjecutivaCuenta);
								
							return (
								<Switch>
									{tieneAltaPreUsuario && <Route path={`${url}/Comercial/AltaUsuario`} component={Operador.AltaUsuario} exact />}
									{tieneFacturacion && <Route path={`${url}/Facturacion/Facturacion`} component={Operador.Facturacion} exact />}
									{tieneABMContrato && <Route path={`${url}/Facturacion/ABMContrato`} component={Operador.ABMContrato} exact />}
									{tieneGuiaRecepcionar && <Route path={`${url}/Validar2`} component={Operador.Validar2} exact />}
									{tieneConfirmarCarpeta && <Route path={`${url}/Confirmar`} component={Operador.Confirmar} exact />}

									{tieneCarpetasDigitalizacion && <Route path={`${url}/Principal`} component={Operador.Desktop} exact />}
									{tieneCarpetasDigitalizacion && <Route path={`${url}/ProcesarCarpeta/Carpeta/:idCarpeta/Caja/:idCaja`} component={Operador.ProcesarCarpeta} />}
									{tieneCarpetasDigitalizacion && <Route path={`${url}/Digitalizar`} component={Operador.Digitalizar} exact />}
									{tieneCarpetasDigitalizacion && <Route path={`${url}/DigitalizarPdf`} component={Operador.DigitalizarPdf} exact />}

									{tieneProgramarRetiro && <Route path={`${url}/ProgramarRetiro`} component={Operador.ProgramarRetiro} exact />}
									{tieneControlValidaciones && <Route path={`${url}/ControlValidaciones`} component={Operador.ControlValidaciones} exact />}
									{tienePyMoa && <Route path={`${url}/Pymoa`} component={Operador.Pymoa} exact />}
									{tieneContratosFaltantes && <Route path={`${url}/ContratosFaltantes`} component={Operador.ContratosFaltantes} exact />}

									<Route component={NotFound} />	
								</Switch>
							);
						}}
					/>

					{/* /Cliente */}
					<Route
						path="/Cliente"
						render={({ match: { url } }) => {
							const tieneReportesPremium = UserService.hasRole(Role.SupervisorDIGEX) 
								|| UserService.hasRole(Role.ReportesPremium) || UserService.hasRole(Role.ReportesPremiumFull);

							const tieneDashboardDespachante = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.DashboardDespachante);
							const tieneGenerarGuia = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GenerarGuia);
							const tieneConsultarGuia = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ConsultarGuia);
							const tieneDocumentacion = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.Documentacion);
							const tieneValidacionFactura = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ValidacionFactura);
							const tieneInstructivoIM = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.InstructivoIM);
							const tieneInstructivoDE = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.InstructivoDE);
							const tieneSeguimiento = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.Seguimiento);
							const tieneCuentaCorriente = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.CuentaCorriente);
							const tieneReportesB = UserService.hasRole(Role.SupervisorDIGEX) 
								|| UserService.hasRole(Role.ReporteBungeImportacion) || UserService.hasRole(Role.ReporteBungeExportacion)
								|| UserService.hasRole(Role.ReporteBungeMovimiento) || UserService.hasRole(Role.ReporteBungeSaldo)
								|| UserService.hasRole(Role.ReporteBungeBoleta) || UserService.hasRole(Role.ReporteBungeDetallado);
							
							const tieneReporteLDC = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteLDC);
							const tieneReporteL = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteLImportacion);
							const tieneReporteDespachosLDC = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReporteDespachosLDC);
							const tienePedidoLegajo = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.PedidoLegajo);

							const tieneAltaPreUsuarioExterno = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.AltaPreUsuarioExterno);

							const tieneGeneradorDeUsuarios = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.GeneradorDeUsuarios);
							const tieneClaveFiscal = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ClaveFiscal);
							const tieneReportesDepositosAfectaciones = UserService.hasRole(Role.SupervisorDIGEX) || UserService.hasRole(Role.ReportesDepositosAfectaciones)
							return (
								<Switch>
									{tieneReportesPremium && <Route path={`${url}/PrincipalIMEXPremium`} component={Cliente.DesktopIMEXPremium} exact />}
									{!tieneReportesPremium && <Route path={`${url}/PrincipalIMEX`} component={Cliente.DesktopIMEX} exact />}

									{tieneDashboardDespachante && <Route path={`${url}/Principal`} component={Cliente.Desktop} exact />}
									{tieneGenerarGuia && <Route path={`${url}/GenerarGuia`} component={Cliente.GenerarGuia} exact />}
									{tieneConsultarGuia && <Route path={`${url}/ConsultarGuia`} component={Cliente.ConsultarGuia} exact />}
									{tieneDocumentacion && <Route path={`${url}/Documentacion`} component={Cliente.Documentacion} exact />}

									{tieneValidacionFactura && <Route path={`${url}/ValidacionFactura`} component={Cliente.ValidacionFactura} exact />}
									{tieneValidacionFactura && <Route path={`${url}/InfoValidados`} component={Cliente.InfoValidados} exact />}

									{tieneInstructivoIM && !tieneReportesPremium && <Route path={`${url}/InstructivoIM`} component={Cliente.InstructivoIM} exact />}
									{tieneInstructivoIM && tieneReportesPremium && <Route path={`${url}/InstructivoIMPremium`} component={Cliente.InstructivoImpremium} exact />}
									{tieneInstructivoDE && <Route path={`${url}/InstructivoDE`} component={Cliente.InstructivoDE} exact />}									{tieneSeguimiento && <Route path={`${url}/Seguimiento`} component={Cliente.Seguimiento} exact />}
									{tieneCuentaCorriente && <Route path={`${url}/CuentaCorriente`} component={Cliente.CuentaCorriente} exact />}
									{tienePedidoLegajo && <Route path={`${url}/PedidoLegajo`} component={Cliente.PedidoLegajo} exact />}

									{(tieneDJVEBunge || tieneConsultarDJVEBunge) && <Route path={`${url}/Bunge/ConsultarDjve`} component={Cliente.DjveMostrados} />}
									{tieneDJVEBunge && <Route path={`${url}/Bunge/AsociarDjve`} component={Cliente.DjveDigitalizados} exact />}

									
									{tieneAltaPreUsuarioExterno && <Route path={`${url}/AltaPreUsuarioExterno`} component={Cliente.AltaPreUsuarioExterno} exact />}
									{tieneGeneradorDeUsuarios && <Route path={`${url}/GeneradorDeUsuarios`} component={Cliente.GeneradorDeUsuarios} exact />}


									{/* REPORTES */}
									{tieneReportesPremium && <Route path={`${url}/Reporte/OperacionesMes`} component={Cliente.OperacionesMes} exact />}
									{tieneReportesPremium && <Route path={`${url}/Reporte/OperacionesProducto`} component={Cliente.OperacionesProducto} exact />}
									{tieneReportesPremium && <Route path={`${url}/Reporte/OperacionesDespachante`} component={Cliente.OperacionesDespachante} exact />}

									{tieneReportesB && <Route path={`${url}/Reporte/ReportesB`} component={Cliente.ReportesB} exact />}
									{tieneReportesB && <Route path={`${url}/Reporte/ReportesBCLiquidaciones`} component={Cliente.ReportesBCLiquidaciones} exact />}
									{tieneReporteLDC && <Route path={`${url}/Reporte/ReporteLDC`} component={Cliente.ReporteLDC} exact />}
									{tieneReporteL && <Route path={`${url}/Reporte/ReporteL`} component={Cliente.ReporteL} exact />}
									{tieneReporteDespachosLDC && <Route path={`${url}/Reporte/ReporteDespachosLDC`} component={Cliente.ReporteDespachosLDC} exact />}
									{tieneReportesDepositosAfectaciones && <Route path={`${url}/Reporte/ReportesDepositosAfectaciones`} component={Cliente.ReportesDepositosAfectaciones} exact />}

									{tieneClaveFiscal && <Route path={`${url}/CargarClaveFiscal`} component={Cliente.CargarClaveFiscal} exact />}
									<Route component={NotFound} />
								</Switch>
							);
						}}
					/>

					{/* /Account */}
					<Route path="/Account/ChangePassword" exact component={ChangePassword} />

					{/* Otros */}
					{tieneVerFamilias && <Route path="/ViewFamilias/Legajo/:idLegajo/Carpeta/:idCarpeta" component={ViewFamilias} />}
					{(tieneDJVEBunge || tieneConsultarDJVEBunge) && <Route path="/ViewDjves/Djve/:cod_djve/ContratoMaestro/:contratoMaestro" component={ViewDjves} />}
					
					
					<Route path="/" exact component={Home} />
				
					<Route component={NotFound} />				
				</Switch>
			</Fragment>
		);
	}

	return <Fragment />;
};

export default AppRouter;
