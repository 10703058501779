import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Banner, LeftMenu, Loading, ModalAlerta } from '../components';

export const Container = (props) => {
	const { showMenu } = useSelector((state) => state.menu);
	const { loadingCounter } = useSelector((state) => state.loader);

	useEffect(() => {
		document.querySelector('#chat').style.display = 'block';
	}, []);

	return (
		<div id="Container" className={`${showMenu ? '' : 'sidebar-collapse'}`}>
			<Banner />
			<LeftMenu />

			<div {...props} className="content-wrapper">
				{props.children}
			</div>
			
			{loadingCounter > 0 && <Loading />}

			<ModalAlerta />
		</div>
	);
};

const Header = (props) => (
	<section className="content-header" {...props}>
		{props.children}
	</section>
);
const Body = (props) => (
	<section className="content" {...props}>
		{props.children}
	</section>
);

Container.Header = Header;
Container.Body = Body;
