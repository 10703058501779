import { useState, useEffect } from 'react';

import {Util} from '../utils/utilidades'

export const EditableCell = ({
	value: initialValue,
	row,
	column: { id },
	type = "",
	name = "",
	handleEditableCell,
	placeholder = "",
	className = "",
	style = {},
	onClick,
	readOnly = false,
	disabled = false
}) => {
	const [value, setValue] = useState(initialValue)

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	useEffect(() => {
		handleEditableCell(row, value, id)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])

	return <input 
		type={type}
		autoComplete="off"
		placeholder={placeholder}
		defaultValue={value}
		onClick={onClick}
		onBlur={(e) => setValue(e.target.value.trim())} 
		className={className} 
		style={style}
		readOnly={readOnly}
		onKeyPress={(e)=> {
			if(e.target.value.length >= 5) e.preventDefault()
			Util.manejadorDeTecla(e) 
		}}
		name={name}
		disabled={disabled}
	/>
}