import { useState, useEffect } from 'react';
import { mostrarModal } from '../../../shared/hooks/modal';
import useApi from '../../../shared/hooks/api';
import { useSelector } from 'react-redux';

export const ClaveExpirada = () => {
	const { user } = useSelector((state) => state.auth);

	const [usuario, asignarUsuario] = useState({
		contraseñaActual: '',
		contraseñaNueva: '',
		contraseñaNuevaConfirmacion: '',
	});

	const [validaciones, asignarValidaciones] = useState({
		longitudMinima: false,
		tieneMayusculas: false,
		tieneNumeros: false,
		tieneSimbolos: false,
	});

	const [, cambiarContraseña] = useApi.post('/cambiarContraseña', usuario, {
		lazy: true,
		headers: { Authorization: user && user.tokenAcceso ? `Bearer ${user.tokenAcceso}` : undefined },
		onSuccess: () => {
			mostrarModal('Alerta', {
				titulo: 'Cambiar Clave',
				mensaje: 'Se ha actualizado su contraseña.',
				handleCallback: () => window.location.href = '/',
			});
		},
		onError: ({ error }) => {
			mostrarModal('Alerta', {
				titulo: 'Error al Cambiar Clave',
				mensaje: error,
			});
		},
	});

	useEffect(() => {
		let validaciones = { longitudMinima: false, tieneMayusculas: false, tieneNumeros: false, tieneSimbolos: false };

		//1: Verifica si contienen la minima cantidad de caracteres:
		if (usuario.contraseñaNueva !== '' && usuario.contraseñaNueva.length >= 9) {
			validaciones.longitudMinima = true;
		}

		//2: Verifica si contiene mayusculas
		if (usuario.contraseñaNueva.match(/[A-Z]/g) !== null && usuario.contraseñaNueva.match(/[A-Z]/g)[0].length > 0) {
			validaciones.tieneMayusculas = true;
		}

		//3: Verifica si tiene numeros
		if (usuario.contraseñaNueva.match(/\d+/g) !== null && usuario.contraseñaNueva.match(/\d/g).length >= 4) {
			validaciones.tieneNumeros = true;
		}

		//4: Verifica si tiene caracteres especiales
		if (usuario.contraseñaNueva.match(/[#¿?¡!@$%^*]/g) !== null && usuario.contraseñaNueva.match(/[#¿?¡!@$%^*]/g).length > 0) {
			validaciones.tieneSimbolos = true;
		}

		asignarValidaciones(validaciones);
	}, [usuario]);

	const manejarCambioClave = () => {
		if (!validaciones.longitudMinima || !validaciones.tieneMayusculas || !validaciones.tieneNumeros || !validaciones.tieneSimbolos) {
			mostrarModal('Alerta', { titulo: 'Cambiar Clave', mensaje: '¡Revise las recomendaciones de seguridad!' });
		} else if (usuario.contraseñaNueva !== usuario.contraseñaNuevaConfirmacion) {
			mostrarModal('Alerta', { titulo: 'Cambiar Clave', mensaje: 'Su nueva contraseña y confirmación deben coincidir.' });
		} else if (usuario.contraseñaActual === '') {
			mostrarModal('Alerta', { titulo: 'Cambiar Clave', mensaje: 'Debe escribir su contraseña anterior.' });
		} else {
			cambiarContraseña();
		}
	};
	
	return (
		<div id="loginClaveExpirada" className="row justify-content-center">
			<div className="col-lg-6">
				<div className="box box-solid box-primary" style={{ backgroundColor: 'transparent' }}>
					<div className="box-header" style={{ marginBottom: 20, backgroundColor: 'transparent' }}>
						<h3 className="box-title">Su contraseña ha expirado, por favor modifíquela:</h3>
					</div>

					<div className="box-body">
						<div
							style={{
								margin: 0,
								borderWidth: 2,
								borderStyle: 'solid',
								borderColor: '#0066ff',
								borderBottomColor: 'transparent',
								borderTopColor: 'transparent',
								borderRightColor: 'transparent',
							}}
							className="row mb-4"
						>
							<div style={{ fontSize: 19 }} className="col-12">
								<label htmlFor="lblUserName" id="lblUserNameLabel">
									Usuario:
								</label>
							</div>
							<div style={{ color: '#0066ff', fontSize: 19 }} className="col-12">
								<span id="lblUserName" style={{ display: 'inline-block', width: '100%' }}>
									{user ? user.nombreUsuario : '...'}
								</span>
							</div>
						</div>

						<div className="row">
							<div className="col-sm-12 col-md-6">
								<div className="row mb-2">
									<div className="col-12">
										<label htmlFor="claveActual" id="claveActual">
											Contraseña anterior:
										</label>
									</div>
									<div className="col-12">
										<input
											name="claveActual"
											autoComplete="off"
											type="password"
											id="claveActual"
											className="digex-control"
											defaultValue={usuario.contraseñaActual}
											onChange={(e) => asignarUsuario({ ...usuario, contraseñaActual: e.target.value })}
										/>
									</div>
								</div>
								<div className="row mb-2">
									<div className="col-12">
										<label htmlFor="nuevaClave" id="nuevaClave">
											Nueva contraseña:
										</label>
									</div>
									<div className="col-12">
										<input
											name="nuevaClave"
											autoComplete="off"
											type="password"
											id="nuevaClave"
											className="digex-control"
											defaultValue={usuario.contraseñaNueva}
											onChange={(e) => asignarUsuario({ ...usuario, contraseñaNueva: e.target.value })}
										/>
									</div>
								</div>
								<div className="row mb-4">
									<div className="col-12">
										<label htmlFor="confirmarClave" id="confirmarClave">
											Confirmar la nueva contraseña:
										</label>
									</div>
									<div className="col-12">
										<input
											name="confirmarClave"
											type="password"
											id="confirmarClave"
											className="digex-control"
											defaultValue={usuario.contraseñaNuevaConfirmacion}
											onChange={(e) => asignarUsuario({ ...usuario, contraseñaNuevaConfirmacion: e.target.value })}
										/>
									</div>
								</div>

								<div className="row mb-2">
									<div className="col-sm-12">
										<button className="btn btn-primary" onClick={manejarCambioClave}>Cambiar contraseña</button>
									</div>
								</div>
							</div>

							<div className="col-sm-12 col-md-6">
								<div className="box-body text-primary p-0">
									<h4 className="box-title ml-2">Recomendaciones de Seguridad</h4>
									<ul className="ml-2">
										<li className="mb-2">
											Las contraseñas nuevas deben tener:
											<ol className="text-secondary mt-2">
												<li className={`${validaciones.longitudMinima ? 'disable' : 'enable'}`}>Longitud mínima de 9 caracteres.</li>
												<li className={`${validaciones.tieneMayusculas ? 'disable' : 'enable'}`}>Al menos una mayúscula.</li>
												<li className={`${validaciones.tieneNumeros ? 'disable' : 'enable'}`}>Al menos 4 (cuatro) digitos numericos.</li>
												<li className={`${validaciones.tieneSimbolos ? 'disable' : 'enable'}`}>Al menos un caracter especial: # ? ! @ $ % ^ *.</li>
											</ol>
										</li>
										<li className="mb-2">No entregue ni divulgue sus credenciales de acceso al sistema.</li>
										<li className="mb-2">
											El sistema exigirá periódicamente el cambio de la contraseña, a fin de maximizar la seguridad del sistema.
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
