import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../shared/state';
import { Loading, ModalAlerta } from '../../../shared/components';
import { mostrarModal } from '../../../shared/hooks/modal';
import logoweb from '../../../shared/assets/images/logoweb.png';
import dgx from '../../../shared/assets/images/dgx.png';

import { default as ModalOlvidoClave } from './OlvidoClave.modal.jsx';
import { default as ModalLoginErroneo } from './LoginErroneo.modal.jsx';

import { ClaveExpirada } from './ClaveExpirada';

const Login = () => {
	const [filter, setFilter] = useState({
		username: '',
		password: '',
		submitted: false,
	});

	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { login } = bindActionCreators(actions, dispatch);
	const { loadingCounter } = useSelector((state) => state.loader);

	const handleSubmit = (e) => {
		e.preventDefault();
		setFilter({ ...filter, submitted: true });
		if (filter.username && filter.password) {
			try {
				login(filter.username, filter.password)
			} catch (err) {
				//console.log(err);
			} finally {
				setFilter({ ...filter, submitted: false });
			}
		} else {
			setFilter({ ...filter, submitted: false });
		}
	};

	return (
		<div id="AccountLogin">
			<div className="container-login">
				{/* BANNER */}
				<nav id="banner-login">
					<div className="my-3 px-4">
						<img src={logoweb} alt="" style={{ width: '185px', height: '65px', marginRight: '30px' }} />

						<div style={{ float: 'right', padding: '20px', marginRight: '30px' }}>
							<a href="/Inicio" className="text-decoration-none">
								INICIO
							</a>
						</div>
					</div>
				</nav>

				{user && user.tieneClaveExpirada === 1 && (
					<ClaveExpirada />
				)}

				{!user && (
					<div id="login">
						<form name="formLogin" onSubmit={handleSubmit} style={{ padding: '22px' }}>
							<div id="LoginUserValidationSummary" className="failureNotification text-danger">
								<ul className="p-0">
									{filter.submitted && !filter.username && <li>El nombre de usuario es obligatorio.</li>}
									{filter.submitted && !filter.password && <li>La contraseña es obligatoria.</li>}
								</ul>
							</div>

							<div className="row">
								<div className="col mb-3">
									<label htmlFor="Username">Nombre de usuario (CUIT):</label>
									<input
										type="text"
										autoComplete="off"
										name="Username"
										id="Username"
										className="digex-control"
										required
										value={filter.username}
										onChange={(e) => setFilter({ ...filter, username: e.target.value.trim() })}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col mb-3">
									<label htmlFor="Password">Contraseña:</label>
									<input
										type="password"
										name="Password"
										id="Password"
										className="digex-control"
										autoComplete="off"
										required
										value={filter.password}
										onChange={(e) => setFilter({ ...filter, password: e.target.value })}
									/>
								</div>
							</div>

							<div className="row justify-content-center" style={{ flexWrap: 'initial', paddingLeft: '15px', paddingRight: '15px' }}>
								<button type="submit" className="btn btn-primary mr-2" disabled={filter.submitted}>
									Iniciar sesión
								</button>
								<button type="button" className="btn btn-primary ml-2" onClick={() => mostrarModal('OlvidoClave')} disabled={filter.submitted}>
									Olvidé mi clave
								</button>
							</div>
						</form>
					</div>
				)}
				
				<ModalLoginErroneo />
				<ModalOlvidoClave />
				<ModalAlerta />

				<footer>
					<div id="footer-login">
						<hr />
						<div>
							<div className="text-right">
								<p>
									<a href="tel:+541143113741" className="text-decoration-none">
										[+5411] 4311.3741
									</a>
									<br />
									<a href="mailto:info@digex.com.ar" className="text-decoration-none">
										info@digex.com.ar
									</a>
									<br />
									
								</p>
							</div>
							<div className="text-center">
								<img src={dgx} className="mx-2" style={{ marginTop: '5px', width: '100px' }} alt="" />
							</div>
							<div className="text-left">
								<p>
									Paraguay 610. Piso 11
									<br />
									C.A.B.A. , Argentina
									<br />
									[C1057AAJ]
								</p>
							</div>
						</div>
					</div>
				</footer>
			</div>

			{loadingCounter > 0 && <Loading />}
		</div>
	);
};

export default Login;
