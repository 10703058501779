import { userTypes } from '../types';

const initialState = { user: null, loading: false };

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case userTypes.userRequest:
			return { ...state, user: action.user, loading: true };
		case userTypes.userSuccess:
			return { ...state, user: action.user, loading: false };
		case userTypes.userFailure:
			return { ...state, user: null, loading: false };
		default:
			return state;
	}
};
